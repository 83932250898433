import React, {useEffect, useState} from 'react';
import {Row, Col, CardBody, Media, Card} from 'reactstrap';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';

import axios from 'axios';

const Stats = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    getReports();
  }, []);

  function getReports() {
    const url = process.env.REACT_APP_APIURL + 'reports';
    const authToken = authHeader();

    return axios.get(url, {headers: authToken}).then((response) => {
      setIsLoading(false);
      setReports(response.data);
    }).catch((error) => {
      setIsLoading(false);
    });
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <Card className="mini-stats-wid h-75">
            <CardBody>
              <Media>
                <Media body>
                  <p className="text-yellow font-weight-bold mb-2">
                      Printer in Total
                  </p>
                  {isLoading ?
                    <i className="bx bx-loader bx-spin font-size-16
                    align-middle mr-2"/> :
                    ''}
                  <h4 className="mb-0">
                    {reports.printer_total}
                  </h4>
                </Media>
                <div className="mini-stat-icon avatar-sm rounded-circle
                bg-primary align-self-center"
                >
                  <span className="avatar-title">
                    <i className="bx bx-printer font-size-24"></i>
                  </span>
                </div>
              </Media>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mini-stats-wid h-75">
            <CardBody>
              <Media>
                <Media body>
                  <p className="text-yellow font-weight-bold mb-2">
                      Printer in guarantee
                  </p>
                  {isLoading ?
                    <i className="bx bx-loader bx-spin font-size-16
                    align-middle mr-2"/> : ''
                  }
                  <h4 className="mb-0">
                    {reports.printer_in_guarantee}
                  </h4>
                </Media>
                <div className="mini-stat-icon avatar-sm rounded-circle
                bg-primary align-self-center"
                >
                  <span className="avatar-title">
                    <i className="bx bx-check font-size-24"></i>
                  </span>
                </div>
              </Media>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mini-stats-wid h-75">
            <CardBody>
              <Media>
                <Media body>
                  <p className="text-yellow font-weight-bold mb-2">
                      Printer out of guarantee
                  </p>
                  {isLoading ?
                    <i className="bx bx-loader bx-spin font-size-16
                    align-middle mr-2"/> : ''}
                  <h4 className="mb-0">
                    {reports.printer_out_of_guarantee}
                  </h4>
                </Media>
                <div className="mini-stat-icon avatar-sm rounded-circle
                bg-primary align-self-center"
                >
                  <span className="avatar-title">
                    <i className="bx bx-x font-size-24"></i>
                  </span>
                </div>
              </Media>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Stats;
