import React from 'react';
import Moment from 'react-moment';
import {Card, CardBody, CardTitle, Media} from 'reactstrap';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import ClaimStatus from '../../components/Claim/ClaimStatus';

const PrinterHistory = (props) => {
  const printer = props.printer;

  const events = [];

  const authUser = getLoggedInUser();

  printer.overtakes && printer.overtakes.map((overtake) => {
    if (overtake.new_customer.id !== overtake.current_customer.id) {
      events.push({
        'date': overtake.created_at,
        'text':
          <>
            Customer changes from
            <Link className="link-highlight"
              to={'/customer/' + overtake.current_customer.id}
            >
              {overtake.current_customer.name}
            </Link>
            to
            <Link
              className="link-highlight"
              to={'/customer/' + overtake.new_customer.id}
            >
              {overtake.new_customer.name}
            </Link>
          </>,
      });
    }
    if (overtake.new_dealer.id !== overtake.current_dealer.id) {
      if (authUser.user.role === 'manager' ||
        authUser.user.role === 'staff' ||
        authUser.user.role === 'sales'
      ) {
        events.push({
          'date': overtake.created_at,
          'text':
            <>
              Dealer changes from
              <Link className="link-highlight"
                to={'/dealer/' + overtake.current_dealer.id}
              >
                {overtake.current_dealer.name}
              </Link>
              to
              <Link
                className="link-highlight"
                to={'/dealer/' + overtake.new_dealer.id}
              >
                {overtake.new_dealer.name}
              </Link>
            </>,
        });
      }
    }
  });
  printer.claims && printer.claims.map((claim) => events.push({
    'date': claim.created_at,
    'text': <React.Fragment>Claim <Link className="link-highlight"
      to={'/claim/' + claim.id}>{claim.fault_report_no}</Link> was
      created</React.Fragment>,
  }));
  printer.claims && printer.claims.filter(
      (claim) => claim.status === 3).map((claim) => events.push({
    'date': claim.closing_date,
    'text': <>Warranty Check <ClaimStatus claim={claim}/></>,
  }));
  // eslint-disable camelcase
  printer.print_head_replacements && printer.print_head_replacements.map(
      // eslint-disable-next-line camelcase
      (print_head_replacement) => events.push({
      // eslint-disable-next-line camelcase
        'date': print_head_replacement.replacement_date,
        'text': <>
          {/*  eslint-disable-next-line camelcase  */}
        Printhead {print_head_replacement.original_serial_no}
          {/*  eslint-disable-next-line camelcase  */}
        was replaced by {print_head_replacement.replaced_serial_no}
        </>,
      }));
  printer.services && printer.services.map((service) => events.push({
    'date': service.date_of_visit,
    'text': <>Service {service.service_type} has been submitted</>,
  }));

  printer.history?.map((historyItem) => {
    let text;
    switch (historyItem.action) {
      case 'machine_installed':
        text = <React.Fragment>Machine {historyItem?.target} has been installed</React.Fragment>;
        break;
      case 'machine_removed':
        text = <React.Fragment>Machine {historyItem.target} has been removed</React.Fragment>;
        break;
      default:
        text = '';
    }

    events.push({
      'date': historyItem.created_at,
      'text': text,
    });
  });
  // eslint-enable camelcase

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">
            Last Activities
          </CardTitle>
          <ul className="verti-timeline list-unstyled">
            {events.sort((a, b) => {
              return new Date(a.date).getTime() -
                new Date(b.date).getTime();
            }).reverse().map(
                (event, index) =>
                  <li className="event-list" key={'event-' + index}>
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"/>
                    </div>
                    <Media>
                      <div className="mr-3">
                        <strong className="font-size-14">
                          <Moment format="DD-MM-YYYY">{event.date}</Moment>
                          <i className="bx bx-right-arrow-alt font-size-16
                          text-primary align-middle ml-2"/>
                        </strong>
                      </div>
                      <Media body>
                        <div>
                          {event.text}
                        </div>
                      </Media>
                    </Media>
                  </li>,
            )}
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18"/>
              </div>
              <Media>
                <div className="mr-3">
                  <strong className="font-size-14">
                    <Moment format="DD-MM-YYYY">
                      {/* eslint-disable-next-line camelcase */}
                      {printer.installation_date}
                    </Moment>
                    <i className="bx bx-right-arrow-alt font-size-16
                    text-primary align-middle ml-2"/>
                  </strong>
                </div>
                <Media body>
                  <div>
                    Printer has been installed
                  </div>
                </Media>
              </Media>
            </li>
          </ul>
          {/* @TODO delete this? */}
          {/* <div className="text-center mt-4">*/}
          {/*  <Link to=""*/}
          {/*    className="btn btn-primary waves-effect waves-light btn-sm">*/}
          {/*    View More*/}
          {/*    <i className="mdi mdi-arrow-right ml-1"/>*/}
          {/*  </Link>*/}
          {/* </div>*/}
        </CardBody>
      </Card>
    </>
  );
};

export default PrinterHistory;
