/**
 * @description selector to get all engineers.
 * @param {*} state
 * @return {*}
 */
export const getEngineersSelector: (state: any) => Engineer[] =
    (state) => ((state.Engineers && state.Engineers.data) ?
      state.Engineers.data : []);

export const getEngineerByIdSelector = (state: any, id: number) =>
  (state?.Engineer?.data?.id === id) ? state.Engineer.data : undefined;
