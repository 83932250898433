import React, {useState} from 'react';
import {Button, Row, Col, Label, Modal, Card} from 'reactstrap';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';
import Dropzone from 'react-dropzone';
import {Link} from 'react-router-dom';
import moment from 'moment';

const ModalConfirmDispatch = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = props.dispatch;

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function toggleModal() {
    setModal(!modal);
    removeBodyCss();
  }

  function handleValidSubmit(event, values) {
    const confirmedDispatch = {
      claim_id: dispatch.id,
      included: values.included,
      date_of_receipt: values.date_of_receipt,
      storage_location: values.storage_location,
      package_damaged: values.package_damaged,
      files: selectedFiles,
    };
    setIsLoading(true);
    confirmDispatch(confirmedDispatch);
  }

  function confirmDispatch(dispatch) {
    const url = process.env.REACT_APP_APIURL + 'dispatch/confirm';
    const authToken = authHeader();

    const formData = new FormData();
    dispatch.files.forEach((file) => {
      formData.append('file[]', file);
    });
    formData.append('data', JSON.stringify(dispatch));

    return axios.post(url, formData, {headers: authToken}).then((response) => {
      setIsLoading(false);
      setSuccessMsg(true);
    }).catch((error) => {
      setIsLoading(false);
      setErrorMsg(true);
    });
  }

  function handleAcceptedFiles(files) {
    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }));

    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <React.Fragment>
      <button className="mr-1 waves-effect waves-light btn btn-yellow
      text-dark font-weight-bold"
      type="button"
      onClick={() => {
        toggleModal();
      }}
      data-toggle="modal"
      data-target=".bs-example-modal-center"
      >
                Confirm
      </button>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <button
            type="button"
            onClick={() => {
              setModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Row>
            <Col md={1}>
              <div className="avatar-sm">
                <span
                  className="avatar-title rounded-circle bg-primary text-white">
                  <i className="bx bx-check-circle bx-sm"></i>
                </span>
              </div>
            </Col>
            <Col md={11}>
              <div className="mb-2">
                <h5 className="modal-title mt-0">Reception of parts</h5>
                <p>Please confirm the following reception</p>
                <AvForm className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <Row>
                    <Col md="12">
                      <h5>{dispatch.fault_report_no}</h5>
                    </Col>
                    <Col md="6">
                      <h6>Included in package</h6>
                      <Label className="font-size-18">
                        {dispatch.print_head_replacements &&
                          /* eslint-disable camelcase */
                          dispatch.print_head_replacements.map(
                              (print_head_replacement, key) =>
                                <div
                                  key={'print_head_replacement_' + key}
                                >
                                  {print_head_replacement.original_serial_no}
                                </div>)}
                        {dispatch.other_part_replacements &&
                          dispatch.other_part_replacements.map(
                              (other_part_replacement, key) =>
                                <div key={'other_part_replacement_' + key}>
                                  {other_part_replacement.number}
                                </div>)
                        }
                      </Label>
                    </Col>
                    <Col md="6">
                      <h6>Sender/Dealer</h6>
                      <strong>{dispatch.dealer.name}</strong>
                      <p>
                        {dispatch.dealer.street} {dispatch.dealer.street_no}
                        <br/>
                        {dispatch.dealer.zip_code} {dispatch.dealer.city}
                        <br/>
                        {dispatch.dealer.country}
                      </p>
                    </Col>
                    <Col md="12">
                      <AvField
                        name="date_of_receipt"
                        label="Date of receipt *"
                        className="form-control"
                        placeholder="Enter date of receipt *"
                        type="date"
                        max={moment().format('YYYY-MM-DD')}
                        validate={{required: {value: true}}}
                        errorMessage="Please provide a valid date."
                      />
                    </Col>
                    <Col md="12">
                      <AvField
                        type="text"
                        name="storage_location"
                        label="Storage place *"
                        validate={{required: {value: true}}}
                        errorMessage="Please provide valid storage place."
                      />
                    </Col>
                    <Col md="12">
                      <AvField
                        type="textarea"
                        name="package_damaged"
                        label="Package damaged?"
                      />
                    </Col>
                    <Col md="12 mb-4">
                      <strong>Photo of Problem</strong>
                      <p className="mb-0">.jpg/.pdf</p>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                        accept=".jpg,.pdf"
                        multiple={false}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i
                                    className="display-4 text-muted bx
                                    bxs-cloud-upload"
                                  />
                                </div>
                                <h4>Drop files here or click to upload.
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border
                              dz-processing dz-image-preview dz-success
                              dz-complete"
                              key={i + '-file'}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                    <Col md="12">
                      <strong>Announced part not included?</strong>
                      <p>Please write an email to gtsupport@brother-ism.com</p>
                    </Col>

                  </Row>
                  <div className="text-sm-right">
                    {isLoading ?
                      <Button
                        className="btn btn-yellow"
                        type="submit"
                        disabled={true}
                      >
                        <i className="bx bx-loader bx-spin font-size-16
                        align-middle mr-2"
                        />
                          Confirm
                      </Button> :
                      <Button className="btn btn-yellow" type="submit">
                        <i className="mdi mdi-check mr-1"/>
                          Confirm
                      </Button>
                    }
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          // setSuccessMsg(false);
          // setModal(false);
          window.location.reload();
        }}
      >
                Dispatch was submitted successfully.
      </SweetAlert> : null}
      {errorMsg ? <SweetAlert
        title="Request could not be submitted!"
        warning
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          setErrorMsg(false);
        }}
      >
          There was an error while submitting your request. Please try again or
          get in contact with our team.
      </SweetAlert> : null}
    </React.Fragment>
  );
};

export default ModalConfirmDispatch;
