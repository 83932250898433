import {
  GET_ENGINEERS_ERROR, GET_ENGINEERS_REQUEST, GET_ENGINEERS_SUCCESS,
} from './actionTypes';

export const getEngineers = () => ({
  type: GET_ENGINEERS_REQUEST,
});

export const getEngineersSuccess = (data) => {
  return {
    type: GET_ENGINEERS_SUCCESS,
    payload: data,
  };
};

export const getEngineersError = (error) => {
  return {
    type: GET_ENGINEERS_ERROR,
    payload: error,
  };
};
