import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, DropdownToggle, DropdownMenu, Row, Col} from 'reactstrap';
import SimpleBar from 'simplebar-react';

import {getDealersSelector} from '../../../selectors/dealer';
import {getDealers} from '../../../store/dealers/actions';
import {getLoggedInUser} from '../../../helpers/fakebackend_helper';
import authHeader from '../../../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';
import {ROLE, RoleNameMap} from '../../Role/constants';

export const SwitchDealerDropdown: FC = () => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const [dealers, setDealers] = useState<Dealer[]>([]);

  const dealersSelector = useSelector((state) => {
    return getDealersSelector(state);
  });

  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  useEffect(() => {
    dispatch(getDealers());
  }, [dispatch]);

  useEffect(() => {
    setDealers(dealersSelector);
  }, [dealersSelector.length]);

  function handleSelectDealer(dealer) {
    const url = process.env.REACT_APP_APIURL + 'auth/switchDealer/' + dealer.id;
    const authToken = authHeader();

    return axios.post(url, null, {headers: authToken}).then((response) => {
      const authUser = getLoggedInUser();
      authUser.user.dealer_name = response.data.user.dealer_name;
      localStorage.setItem('authUser', JSON.stringify(authUser));
      window.location.reload();
    }).catch((err) => {
      throw err[1];
    });
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        { (userRole === ROLE.BROTHER_STAFF ||
            userRole === ROLE.BROTHER_MANAGER) &&
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown"
          >
            <i className="fa fa-fw fa-exchange-alt font-size-16"/>
            <span className="font-size-13 ml-2">Switch Dealer</span>
          </DropdownToggle>
        }

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Switch Dealer</h6>
                <div className="mt-2 mb-2">
                  <strong>Current Dealer:</strong> {authUser.user.dealer_name}
                </div>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{height: '230px'}}>

            {dealers && dealers.map((dealer, index) =>
              <div
                key={`dealer_${index}`}
                className="text-reset cursor-pointer p-2"
                onClick={() => handleSelectDealer(dealer)}
              >
                <div className="media">
                  <div className="avatar-xs mr-3">
                    <span
                      className="avatar-title bg-primary rounded-circle
                      font-size-16"
                    >
                      <i className="bx bx-user"/>
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-auto mb-auto">
                      {authUser.user.dealer_name === dealer.name ?
                          <strong>{dealer.name}</strong> : dealer.name}
                    </h6>
                  </div>
                </div>
              </div>)}

          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default SwitchDealerDropdown;
