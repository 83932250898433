import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

// Login Redux States
import {RESET_PASSWORD} from './actionTypes';
import {userResetPasswordSuccess, userResetPasswordError} from './actions';

// Include Both Helper File with needed methods
import {postResetPwd} from '../../../helpers/fakebackend_helper';

// If user is send successfully send mail link
// then dispatch redux action's are directly from here.
/**
 * resets user
 */
function* resetUser({payload: {data}}) {
  try {
    const response = yield call(
        postResetPwd,
        process.env.REACT_APP_APIURL + 'auth/reset',
        data,
    );
    if (response) {
      yield put(
          userResetPasswordSuccess(
              'New password has been set. Login with your new password.',
          ),
      );
    }
  } catch (error) {
    yield put(userResetPasswordError(error));
  }
}

/**
 * Watcher
 */
export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetUser);
}

/**
 * Saga
 */
function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)]);
}

export default resetPasswordSaga;
