import authHeader from '../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

// @TODO delete this?
// const getCustomerFromBackend = async (customerId = 1) => {
//     try {
//         const response = await fetch(process.env.REACT_APP_APIURL
//         + 'customer/'+ customerId);
//         const data = await response.json();
//         return data;
//     } catch (e) {
//         console.log(e);
//     }
// }

const getCustomerFromBackend = async (customerId: number) => {
  const url = process.env.REACT_APP_APIURL + 'customer/'+ customerId;
  const authToken = authHeader();

  return await axios.get(url, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((err) => {
    throw err[1];
  });
};

const createCustomerInBackend = (customer: CreateAPICustomer) => {
  const url = process.env.REACT_APP_APIURL + 'customer';
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(customer));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    // @TODO delete this?
    // if (response.status === 422 || response.status === 500)
    //     return response.data;
    return response.data;
  }).catch((error) => {
    // @TODO delete this?
    // throw err[1];
    return error.response.data;
  });
};

const editCustomerInBackend = (customer: EditAPICustomer) => {
  const url = process.env.REACT_APP_APIURL + 'customer/edit/' + customer.id;
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(customer));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
};

// @TODO delete this?
// const createCustomerInBackend = async (customer) => {
//     try {
//         const formData = new FormData();
//         formData.append('data', JSON.stringify(customer));
//         const response = await fetch(process.env.REACT_APP_APIURL +
//         'customer', {
//             method: 'post',
//             headers: {
//                 'Accept': 'application/json, text/plain, */*',
//                 // 'Content-Type': 'application/json'
//             },
//             body: formData
//         });
//         return await response.json();
//     } catch (e) {
//         console.log(e);
//     }
// }

export {getCustomerFromBackend, createCustomerInBackend, editCustomerInBackend};
