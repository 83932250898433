// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {
  GET_DEALER_REQUEST,
  CREATE_DEALER_REQUEST,
  EDIT_DEALER_REQUEST,
} from './actionTypes';
import {
  createDealerSuccess,
  createDealerError,
  getDealerSuccess,
  getDealerError,
  editDealerSuccess,
  editDealerError,
} from './actions';
import {
  getDealerFromBackend,
  createDealerInBackend,
  editDealerInBackend,
} from '../../api/dealer';

/**
 * Retrieving Dealers
 * @param {*} param0
 */
function* getDealer({payload: dealerId}) {
  try {
    const data = yield call(getDealerFromBackend, dealerId);
    yield put(getDealerSuccess(data));
  } catch (error) {
    yield put(getDealerError(error));
  }
}

/**
 * Create Dealer
 * @param {*} param0
 */
function* createDealer({payload: dealer}) {
  try {
    const data = yield call(createDealerInBackend, dealer);
    yield put(createDealerSuccess(data));
  } catch (error) {
    yield put(createDealerError(error));
  }
}

/**
 * Edit Dealer
 * @param {*} param0
 */
function* editDealer({payload: dealer}) {
  try {
    const data = yield call(editDealerInBackend, dealer);
    yield put(editDealerSuccess(data));
  } catch (error) {
    yield put(editDealerError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_DEALER_REQUEST, getDealer);
  yield takeEvery(CREATE_DEALER_REQUEST, createDealer);
  yield takeEvery(EDIT_DEALER_REQUEST, editDealer);
}

/**
 * Saga
 */
function* DealerSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default DealerSaga;
