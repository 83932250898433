import {all} from 'redux-saga/effects';

// public
import accountSaga from './auth/register/saga';
import authSaga from './auth/login/saga';
import forgetSaga from './auth/forgetpwd/saga';
import profileSaga from './auth/profile/saga';
import resetSaga from './auth/resetpwd/saga';
import layoutSaga from './layout/saga';
import claimsSaga from './claims/saga';
import claimSaga from './claim/saga';
import printersSaga from './printers/saga';
import printerSaga from './printer/saga';
import customersSaga from './customers/saga';
import customerSaga from './customer/saga';
import dispatchesSaga from './dispatches/saga';
import dealersSaga from './dealers/saga';
import dealerSaga from './dealer/saga';
import engineersSaga from './engineers/saga';
import engineerSaga from './engineer/saga';
import servicesSaga from './services/saga';
import overtakesSaga from './overtakes/saga';
import machinesSaga from './machines/saga';
import machineSaga from './machine/saga';
import notificationsSaga from './notifications/saga';
import notificationSaga from './notification/saga';
import userNotificationsSaga from './user_notifications/saga';

/**
 * Saga
 */
export default function* rootSaga() {
  yield all([
    // public
    accountSaga(),
    authSaga(),
    claimsSaga(),
    claimSaga(),
    customersSaga(),
    customerSaga(),
    dispatchesSaga(),
    dealersSaga(),
    dealerSaga(),
    engineersSaga(),
    engineerSaga(),
    forgetSaga(),
    layoutSaga(),
    machinesSaga(),
    machineSaga(),
    notificationsSaga(),
    notificationSaga(),
    overtakesSaga(),
    printersSaga(),
    printerSaga(),
    profileSaga(),
    resetSaga(),
    servicesSaga(),
    userNotificationsSaga(),
  ]);
}
