import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Footer from './HorizontalLayout/Footer';

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state={};
    this.capitalizeFirstLetter.bind(this);
  }

    capitalizeFirstLetter = (string) => {
      return string.charAt(1).toUpperCase() + string.slice(2);
    };

    componentDidMount() {
      const currentage = this.capitalizeFirstLetter(
          this.props.location.pathname,
      );

      document.title =
          currentage + ' | Brother TECNET | IPM Dealer Service Platform';
    }
    render() {
      return <React.Fragment>
        {this.props.children}
        <Footer/>
      </React.Fragment>;
    }
}

export default (withRouter(NonAuthLayout));
