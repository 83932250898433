import {
  GET_PRINTERS_ERROR,
  GET_PRINTERS_REQUEST,
  GET_PRINTERS_SUCCESS,
} from './actionTypes';

export const getPrinters = () => ({
  type: GET_PRINTERS_REQUEST,
});

export const getPrintersSuccess = (data) => {
  return {
    type: GET_PRINTERS_SUCCESS,
    payload: data,
  };
};

export const getPrintersError = (error) => {
  return {
    type: GET_PRINTERS_ERROR,
    payload: error,
  };
};


