// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';

import {
  GET_MACHINES_REQUEST,
} from './actionTypes';
import {getMachinesError, getMachinesSuccess} from './actions';

import {getMachinesFromBackend} from '../../api/machines';

/**
 * Retrieving machines
 * @param {*} param0
 */
function* getMachines({payload: user}) {
  try {
    const data = yield call(getMachinesFromBackend);
    yield put(getMachinesSuccess(data));
  } catch (error) {
    yield put(getMachinesError(error));
  }
}

/**
 * Watchers
 */
export function* watchGetMachines() {
  yield takeEvery(GET_MACHINES_REQUEST, getMachines);
}
/**
 * Saga
 */
function* machinesSaga() {
  yield all([
    fork(watchGetMachines),
  ]);
}

export default machinesSaga;
