import React, {FC} from 'react';

// @TODO add some props to individualize
interface LoadingIndicatorProps {
    fontSize?: number,
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({fontSize}) => {
  return (
    <i
      className={'bx bx-loader bx-spin align-middle font-size-' +
        (fontSize || 16)}
    />
  );
};
