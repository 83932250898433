import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

// Pages Components
import WelcomeComp from './WelcomeComp';
import LatestClaims from './LatestClaims';
import QuickNavigation from './QuickNavigation';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// i18n
import {withTranslation} from 'react-i18next';
import Stats from './Stats';
import PrinterInstalledChart from './PrinterInstalledChart';
import LatestPrinters from './LatestPrinters';
import LatestServices from './LatestServices';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import LatestCustomers from './LatestCustomers';

const Dashboard = (props) => {
  const authUser = getLoggedInUser();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t('Dashboard')}
            breadcrumbItem={props.t('Dashboard')}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp/>
              <QuickNavigation/>
              {['sales', 'dealer'].includes(authUser.user.role) &&
                            <LatestCustomers/>
              }
            </Col>
            <Col xl="8">
              <Stats/>
              <PrinterInstalledChart/>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestClaims/>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestPrinters/>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestServices/>
            </Col>
          </Row>

          {/* <Row>*/}
          {/*  <Col xl="4">*/}
          {/*    <Card>*/}
          {/*      <CardBody>*/}
          {/*        <CardTitle className="mb-4">*/}
          {/*            Remote Service*/}
          {/*        </CardTitle>*/}
          {/*        <Row>*/}
          {/*          <Col sm="6">*/}
          {/*            <button*/}
          {/*              className="btn btn-primary btn-block waves-effect*/}
          {/*              waves-light mb-1"*/}
          {/*            >*/}
          {/*                Request a Remote Call*/}
          {/*            </button>*/}
          {/*          </Col>*/}
          {/*        </Row>*/}
          {/*      </CardBody>*/}
          {/*    </Card>*/}

          {/*    <ActivityComp/>*/}
          {/*  </Col>*/}

          {/*  <Col xl="4">*/}
          {/*    <ActivityComp/>*/}
          {/*  </Col>*/}

          {/*  <Col xl="4">*/}
          {/*    <ActivityComp/>*/}
          {/*  </Col>*/}
          {/* </Row>*/}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Dashboard);
