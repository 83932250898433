import {
  GET_PRINTER,
  CREATE_PRINTER,
  PRINTER_RECEIVED, PRINTER_CREATED,
} from './actionTypes';

export const getPrinter = (printerSerial) => ({
  type: GET_PRINTER,
  payload: printerSerial,
});

export const printerReceived = (data) => {
  return {
    type: PRINTER_RECEIVED,
    payload: data,
  };
};

export const createPrinter = (printer) => ({
  type: CREATE_PRINTER,
  payload: printer,
});

export const printerCreated = (printer) => {
  return {
    type: PRINTER_CREATED,
    payload: printer,
  };
};
