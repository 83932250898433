// @flow

import {GET_OVERTAKES_SUCCESS} from './actionTypes';

const initialState = {

};

const Overtakes = (state = initialState, action) => {
  switch (action.type) {
    case GET_OVERTAKES_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default Overtakes;
