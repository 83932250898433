// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {
  GET_NOTIFICATION_REQUEST,
  CREATE_NOTIFICATION_REQUEST,
  EDIT_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_REQUEST,
} from './actionTypes';
import {
  createNotificationSuccess,
  createNotificationError,
  editNotificationSuccess,
  editNotificationError,
  deleteNotificationSuccess,
  deleteNotificationError,
  getNotificationSuccess,
  getNotificationError,
} from './actions';
import {
  getNotificationFromBackend,
  createNotificationInBackend,
  editNotificationInBackend,
  deleteNotificationInBackend,
} from '../../api/notification';

// @TODO Umstellung TS

/**
 * Retrieving Notification
 * @param {*} param0
 */
function* getNotification({payload: notificationId}) {
  try {
    const data = yield call(getNotificationFromBackend, notificationId);
    yield put(getNotificationSuccess(data));
  } catch (error) {
    yield put(getNotificationError(error));
  }
}

/**
 * Create Notification
 * @param {*} param0
 */
function* createNotification({payload: notification}) {
  try {
    const data = yield call(createNotificationInBackend, notification);
    yield put(createNotificationSuccess(data));
  } catch (error) {
    yield put(createNotificationError(error));
  }
}

/**
 * Edit Notification
 * @param {*} param0
 */
function* editNotification({payload: notification}) {
  try {
    const data = yield call(editNotificationInBackend, notification);
    yield put(editNotificationSuccess(data));
  } catch (error) {
    yield put(editNotificationError(error));
  }
}

/**
 * Delete Notification
 * @param {*} param0
 */
function* deleteNotification({payload: notification}) {
  try {
    const data = yield call(deleteNotificationInBackend, notification);
    yield put(deleteNotificationSuccess(data));
  } catch (error) {
    yield put(deleteNotificationError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_NOTIFICATION_REQUEST, getNotification);
  yield takeEvery(CREATE_NOTIFICATION_REQUEST, createNotification);
  yield takeEvery(EDIT_NOTIFICATION_REQUEST, editNotification);
  yield takeEvery(DELETE_NOTIFICATION_REQUEST, deleteNotification);
}

/**
 * Saga
 */
function* NotificationSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default NotificationSaga;
