/**
 * @description selector to get all notifications.
 * @param {*} state
 * @return {*}
 */
export const getNotificationsSelector: (state: any) => OverviewNotification[] =
    (state) => ((state.Notifications && state.Notifications.data) ?
      state.Notifications.data : []);

export const getNotificationByIdSelector = (state: any, id: number) =>
  (state?.Notification?.data?.id === id) ? state.Notification.data : undefined;
