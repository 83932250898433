import {
  CREATE_MACHINE_ERROR,
  CREATE_MACHINE_REQUEST,
  CREATE_MACHINE_SUCCESS,
  EDIT_MACHINE_ERROR,
  EDIT_MACHINE_REQUEST,
  EDIT_MACHINE_SUCCESS,
  GET_MACHINE_ERROR,
  GET_MACHINE_REQUEST,
  GET_MACHINE_SUCCESS,
} from './actionTypes';

export const getMachine = (machineId: number) => ({
  type: GET_MACHINE_REQUEST,
  payload: machineId,
});

export const getMachineSuccess = (data) => {
  return {
    type: GET_MACHINE_SUCCESS,
    payload: data,
  };
};

export const getMachineError = (error) => {
  return {
    type: GET_MACHINE_ERROR,
    payload: error,
  };
};

export const createMachine = (machine: CreateAPIMachine) => ({
  type: CREATE_MACHINE_REQUEST,
  payload: machine,
});

export const createMachineSuccess = (machine: CreateAPIMachine) => {
  return {
    type: CREATE_MACHINE_SUCCESS,
    payload: machine,
  };
};

export const createMachineError = (error) => {
  return {
    type: CREATE_MACHINE_ERROR,
    payload: error,
  };
};

export const editMachine = (machine: Machine) => ({
  type: EDIT_MACHINE_REQUEST,
  payload: machine,
});

export const editMachineSuccess = (machine: Machine) => {
  return {
    type: EDIT_MACHINE_SUCCESS,
    payload: machine,
  };
};

export const editMachineError = (error) => {
  return {
    type: EDIT_MACHINE_ERROR,
    payload: error,
  };
};
