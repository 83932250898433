import React, {Component} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// actions
import {
  getDispatches,
} from '../../store/actions';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import ModalConfirmDispatch from
  '../../components/Modals/modal-confirmdispatch';

class DispatchOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {searchWaiting: '', searchCompleted: ''};
  }

  componentDidMount() {
    this.props.getDispatches();
  }

  /* eslint-disable camelcase */
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Receipt of parts" breadcrumbItem="Overview"/>
            <Row>
              <Col sm="6">
                <Card>
                  <CardTitle className="px-3 pt-3">Upcoming</CardTitle>
                  <div className="form-group text-right dispatch-search">
                    <i className="bx bx-search"></i>
                    <input
                      className="form-control"
                      name="search_waiting"
                      placeholder="Search"
                      id="search_waiting"
                      type="text"
                      onInput={(e) => {
                        e.target.value = ('' + e.target.value).toUpperCase();
                        this.setState({searchWaiting: e.target.value});
                      }}
                    />
                  </div>
                  <CardBody>
                    {
                      this.props.data ?
                        this.props.data.filter(
                            (dispatch) => dispatch.status === 1 &&
                            (this.state.searchWaiting !== '' ?
                              dispatch.fault_report_no.includes(
                                  this.state.searchWaiting,
                              ) : true) ).map(
                            (dispatch, key) =>
                              <Card
                                className="card-bordered"
                                key={'_dispatch_upcoming_' + key}
                              >
                                <CardBody>
                                  <div className="d-flex mb-3">
                                    <div className="flex-grow-1">
                                      <h5 className="mb-0">
                                        {dispatch.fault_report_no}
                                      </h5>
                                      <p className="text-muted mb-1
                                      font-size-11 font-weight-bold"
                                      >
                                        Date of Claim:
                                        <Moment
                                          format="DD. MMM, YYYY"
                                        >
                                          {dispatch.date_of_visit}
                                        </Moment>
                                      </p>
                                    </div>
                                    <div className="flex-shrink-1">
                                      <span
                                        className="badge badge-pill bg-light
                                        text-dark font-weight-bold"
                                      >
                                        Waiting
                                      </span>
                                    </div>
                                  </div>
                                  {
                                    dispatch.print_head_replacements &&
                                    dispatch.print_head_replacements.map(
                                        (print_head_replacement, key) =>
                                          <Row
                                            key={
                                              '_print_head_replacement_' + key
                                            }
                                          >
                                            <Col md={3}>
                                              <p
                                                className="font-size-13
                                                text-yellow font-weight-bold
                                                mb-1"
                                              >
                                            Item
                                              </p>
                                              <strong>
                                                {print_head_replacement.original_serial_no} {/* eslint-disable-line max-len */}
                                              </strong>
                                            </Col>
                                            <Col md={9}>
                                              <p
                                                className="font-size-13
                                                text-yellow font-weight-bold
                                                mb-1"
                                              >
                                            Dealer
                                              </p>
                                              <strong>
                                                {dispatch.dealer.name}
                                              </strong>
                                              <p>
                                                {dispatch.dealer.street}
                                                {dispatch.dealer.street_no}
                                                <br/>
                                                {dispatch.dealer.zip_code}
                                                {dispatch.dealer.city}
                                                <br/>
                                                {dispatch.dealer.country}
                                              </p>
                                            </Col>
                                          </Row>,
                                    )
                                  }
                                  {
                                    dispatch.other_part_replacements &&
                                    dispatch.other_part_replacements.map(
                                        (other_part_replacement, key) =>
                                          <Row
                                            key={
                                              'other_part_replacements' + key
                                            }
                                          >
                                            <Col md={3}>
                                              <p
                                                className="font-size-13
                                                text-yellow font-weight-bold
                                                mb-1"
                                              >
                                            Item
                                              </p>
                                              <strong>
                                                {other_part_replacement.number}
                                              </strong>
                                            </Col>
                                            <Col md={9}>
                                              <p
                                                className="font-size-13
                                                text-yellow font-weight-bold
                                                mb-1"
                                              >
                                            Dealer
                                              </p>
                                              <strong>
                                                {dispatch.dealer.name}
                                              </strong>
                                              <p>
                                                {dispatch.dealer.street}
                                                {dispatch.dealer.street_no}
                                                <br/>
                                                {dispatch.dealer.zip_code}
                                                {dispatch.dealer.city}
                                                <br/>
                                                {dispatch.dealer.country}
                                              </p>
                                            </Col>
                                          </Row>,
                                    )
                                  }
                                  <ModalConfirmDispatch dispatch={dispatch}/>
                                </CardBody>
                              </Card>,
                        ) : ''
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card>
                  <div className="d-flex">
                    <CardTitle className="px-3 pt-3">Completed</CardTitle>
                    <div className="form-group text-right dispatch-search">
                      <i className="bx bx-search"></i>
                      <input
                        className="form-control"
                        name="search_completed"
                        placeholder="Search"
                        id="search_completed"
                        type="text"
                        onInput={(e) => {
                          e.target.value = ('' + e.target.value).toUpperCase();
                          this.setState({searchCompleted: e.target.value});
                        }}
                      />
                    </div>
                  </div>
                  <CardBody>
                    {
                      this.props.data ? this.props.data.filter(
                          (dispatch) => dispatch.status === 2 &&
                          (this.state.searchCompleted !== '' ?
                            dispatch.fault_report_no.includes(
                                this.state.searchCompleted,
                            ) : true) ).map(
                          (dispatch, key) =>
                            <Card
                              className="card-bordered"
                              key={'_dispatch_completed_' + key}
                            >
                              <CardBody>
                                <div className="d-flex mb-3">
                                  <div className="flex-grow-1">
                                    <h5 className="mb-0">
                                      {dispatch.fault_report_no}
                                    </h5>
                                    <p
                                      className="text-muted mb-1 font-size-11
                                      font-weight-bold">
                                      Date of Claim:
                                      <Moment format="DD. MMM, YYYY">
                                        {dispatch.date_of_visit}
                                      </Moment>
                                    </p>
                                  </div>
                                  <div className="flex-shrink-1">
                                    <span
                                      className="badge badge-pill
                                      badge-primary bg-yellow text-dark
                                      font-weight-bold"
                                    >
                                      Done
                                    </span>
                                  </div>
                                </div>
                                {
                                  dispatch.print_head_replacements.map(
                                      (print_head_replacement, key) =>
                                        <Row
                                          key={'_print_head_replacement_' + key}
                                        >
                                          <Col md={3}>
                                            <p
                                              className="font-size-13
                                              text-yellow font-weight-bold
                                              mb-1"
                                            >
                                              Item
                                            </p>
                                            <strong>
                                              {print_head_replacement.original_serial_no} {/* eslint-disable-line max-len */}
                                            </strong>
                                          </Col>
                                          <Col md={9}>
                                            <p
                                              className="font-size-13
                                              text-yellow font-weight-bold
                                              mb-1"
                                            >
                                              Dealer
                                            </p>
                                            <strong>
                                              {dispatch.dealer.name}
                                            </strong>
                                            <p>
                                              {dispatch.dealer.street}
                                              {dispatch.dealer.street_no}
                                              <br/>
                                              {dispatch.dealer.zip_code}
                                              {dispatch.dealer.city}
                                              <br/>
                                              {dispatch.dealer.country}
                                            </p>
                                          </Col>
                                        </Row>,
                                  )
                                }
                                {
                                  dispatch.other_part_replacements &&
                                  dispatch.other_part_replacements.map(
                                      (other_part_replacement, key) =>
                                        <Row
                                          key={'other_part_replacements' + key}
                                        >
                                          <Col md={3}>
                                            <p
                                              className="font-size-13
                                              text-yellow font-weight-bold mb-1"
                                            >
                                          Item
                                            </p>
                                            <strong>
                                              {other_part_replacement.number}
                                            </strong>
                                          </Col>
                                          <Col md={9}>
                                            <p className="font-size-13
                                            text-yellow font-weight-bold mb-1"
                                            >
                                          Dealer
                                            </p>
                                            <strong>
                                              {dispatch.dealer.name}
                                            </strong>
                                            <p>
                                              {dispatch.dealer.street}
                                              {dispatch.dealer.street_no}
                                              <br/>
                                              {dispatch.dealer.zip_code}
                                              {dispatch.dealer.city}
                                              <br/>
                                              {dispatch.dealer.country}
                                            </p>
                                          </Col>
                                        </Row>,
                                  )
                                }
                              </CardBody>
                            </Card>,
                      ) : ''
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {data} = state.Dispatches;
  return {data};
}
;

export default connect(mapStatetoProps,
    {
      getDispatches,
    },
)(DispatchOverview);
