import React from 'react';

const MachineStatus = (props) => {
  /* eslint-disable camelcase */
  const machine = props.machine;

  return (
    <>
      {machine.guarantee_state === 1 ?
        <span className="badge badge-soft-success font-size-14">
          <i className="bx bx-check"/>
        </span> :
        <span className="badge badge-soft-danger font-size-14">
          <i className="bx bx-x"/>
        </span>
      }
      <span
        className="ml-2 px-1 font-weight-bold">{machine.machine_serial_no}</span>
    </>
  );
};

export default MachineStatus;
