import React, {useState, useEffect} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button, Label, Alert,
} from 'reactstrap';
import {connect} from 'react-redux';
import {withRouter, Link, useHistory} from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';
import '@availity/reactstrap-validation-select/styles.scss';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {apiError} from '../../store/auth/login/actions';
import ListPrinters from '../../components/Lists/ListPrinters';
import moment from 'moment';
import {createMachine} from '../../store/machine/actions';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

const MachineAdd = (props) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [serialStatus, setSerialStatus] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    if (props.data && props.data.success) {
      setSuccessMsg(true);
    } else if (props.data && props.data.error) {
      setErrorMsg(props.data.error);
    }
  }, [props.data]);

  function handleSelectPrinter(selectedPrinter) {
    setSelectedPrinter(selectedPrinter);
  }

  function handleChangeMachineSerial(machineSerialNo) {
    if (machineSerialNo.length === 8) {
      const url = process.env.REACT_APP_APIURL + 'machine/checkduplicate';
      const authToken = authHeader();

      const formData = new FormData();
      formData.append('serial', machineSerialNo);

      return axios.post(url, formData, {headers: authToken}).then(
          (response) => {
            setSerialStatus(response.status === 201);
          }).catch(() => {
        setSerialStatus(false);
      });
    }
  }

  function handleValidSubmit(event, values) {
    setIsLoading(true);

    const machine = {
      machine_serial_no: values.machine_serial_no,
      printer_serial_no: selectedPrinter.printer_serial_no,
      customer_id: selectedPrinter.customer.id,
      installation_date: values.installation_date,
      number_of_prints: values.number_of_prints,
    };
    props.createMachine(machine);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            parent="/machines"
            title="Machine"
            breadcrumbItem="Add machine"
          />

          <Row>
            <Col xs="12">
              <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}>
                {errorMsg ?
                  <SweetAlert
                    title="Error!"
                    danger
                    showConfirm={true}
                    showCancel={false}
                    confirmBtnBsStyle="danger"
                    closeOnConfirm={true}
                    onConfirm={() => {
                      setErrorMsg(false);
                    }}
                  >
                    {errorMsg}
                  </SweetAlert> : ''
                }
                {successMsg ? <SweetAlert
                  title="Good job!"
                  success
                  showConfirm={true}
                  showCancel={false}
                  confirmBtnBsStyle="yellow"
                  onConfirm={() => {
                    history.push('/machines');
                  }}
                >
                                    The machine has been added!
                </SweetAlert> : null}
                <Card>
                  <CardBody>
                    <CardTitle>Machine Informations</CardTitle>
                    <CardSubtitle
                      className="mb-3"
                    >
                        Fill all information below
                    </CardSubtitle>
                    <Row>
                      <Col md="4">
                        <Label htmlFor="printer-serialno" className="col-form-label"><strong>Printer SERIAL No.</strong></Label>
                        <ListPrinters
                          filter={['gtxpro', 'gtxprobulk']}
                          onChange={(printer) => {
                            handleSelectPrinter(printer);
                          }}/>
                      </Col>
                    </Row>

                    {selectedPrinter && selectedPrinter.customer ?
                          <Row>
                            <Col lg="4" className="mb-3">
                              <div className="border p-3 rounded full-height">
                                <div className="box-icon font-size-16">
                                  <Link to={'/customer/' + selectedPrinter.customer.id}>
                                    <i className='bx bxs-user-circle'/>
                                  </Link>
                                </div>
                                <div>
                                  <h6>Customer</h6>
                                  <h5>{selectedPrinter.customer.name}</h5>
                                  <p className="mb-2"
                                  >
                                    {selectedPrinter.customer.street} {selectedPrinter.customer.street_no}
                                    <br/>
                                    {selectedPrinter.customer.zip_code} {selectedPrinter.customer.city}
                                    <br/>
                                    {selectedPrinter.customer.post_code} {selectedPrinter.customer.country}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row> : ''
                    }
                    <Row>
                      <Col md="4">
                        <AvField
                          name="machine_serial_no"
                          value=""
                          className="form-control"
                          placeholder="R2R Unit Serial"
                          label="R2R Unit Serial"
                          type="text"
                          validate={
                            {
                              required: {value: true},
                              pattern: {value: '^[0-9]+$'},
                              minLength: {value: 8},
                              maxLength: {value: 8},
                              async: (value, ctx, input, cb) => {
                                cb(serialStatus);
                              },
                            }
                          }
                          onChange={
                            (e) => handleChangeMachineSerial(e.target.value)
                          }
                          errorMessage="Please provide a valid R2R Unit Serial."
                          helpMessage="Example: 001 please input as 00000001 (8 digit number)"
                        />
                        {!serialStatus && <Alert color="danger">This serial number already exists!</Alert>}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvField
                          name="installation_date"
                          value=""
                          className="form-control"
                          placeholder="Installation Date of Machine"
                          label="Installation Date of Machine"
                          type="date"
                          max={moment().format('YYYY-MM-DD')}
                          validate={{required: {value: true}}}
                          errorMessage="Provide a valid installation date."
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <AvField
                          name="number_of_prints"
                          value=""
                          className="form-control"
                          placeholder="Total prints of the printer"
                          label="Total prints of the printer"
                          type="number"
                          validate={{
                            required: {value: true},
                            pattern: {value: '^[0-9]+$'},
                          }}
                          errorMessage="Provide a valid number."
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Row className="my-4">
                  <Col sm="6">
                    <Link to="/machines"
                      className="btn d-none d-sm-inline-block btn-link"
                    >
                      <i className="mdi mdi-arrow-left mr-1"/>
                        Back to Machines Overview
                    </Link>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-right">
                      {isLoading ?
                        <Button
                          className="btn btn-yellow"
                          type="submit"
                          disabled={true}
                        >
                          <i className="bx bx-loader bx-spin font-size-16
                          align-middle mr-2"/>
                            Submit Machine
                        </Button> :
                        <Button className="btn btn-yellow" type="submit">
                          <i className="mdi mdi-plus-thick mr-1"/>
                            Submit Machine
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>

              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = (state) => {
  const {data} = state.Machine;
  state.Machine = {};
  return {data};
};

export default withRouter(connect(
    mapStatetoProps, {createMachine, apiError},
)(MachineAdd));
