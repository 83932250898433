import React, {FC} from 'react';
import {Button} from 'reactstrap';
import {LoadingIndicator} from '../LoadingIndicator';
import {Badge} from 'react-bootstrap';

/* eslint-disable no-unused-vars */
export enum BUTTON_BACKGROUND_COLOR {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  WHITE = 'white',
  TRANSPARENT = 'transparent',
  WARNING = 'warning',
  SUCCESS = 'success',
}
/* eslint-enable no-unused-vars */

export interface CountButtonProps {
  active?: boolean,
  activeColor?: BUTTON_BACKGROUND_COLOR,
  backgroundColor?: BUTTON_BACKGROUND_COLOR,
  className?: string,
  disabled?: boolean,
  onClick: (any) => void,
  count: number,
  isLoading?: boolean
  children: React.ReactNode
}

export const CountButton: FC<CountButtonProps> = (
    {
      active,
      activeColor = BUTTON_BACKGROUND_COLOR.PRIMARY,
      backgroundColor = BUTTON_BACKGROUND_COLOR.DEFAULT,
      children,
      className,
      count = 0,
      onClick,
      isLoading,
    }) => {
  return (
    <Button
      color={active ? activeColor : backgroundColor}
      className={className}
      onClick={onClick}
    >
      {children}
      {' '}
      <Badge pill variant='success' className='badge-soft-success'>
        {!isLoading ? count : <LoadingIndicator fontSize={12} />}
      </Badge>
    </Button>
  );
};
