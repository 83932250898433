import React, {useState} from 'react';
import {Button, Row, Col, Modal} from 'reactstrap';
import {
  AvField, AvForm, AvRadioGroup, AvRadio,
} from 'availity-reactstrap-validation';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Printhead from '../../pages/Printers/printhead';
import PrintheadStatus from '../../pages/Printers/printhead-status';
import moment from 'moment';

const ModalPrintheadExchange = (props) => {
  const printer = props.printer;

  const [modal, setModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const model = {};

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function toggleModal() {
    setModal(!modal);
    removeBodyCss();
  }

  function handleValidSubmit(event, values) {
    const printHeadExchange = {
      printer_serial_no: printer.printer_serial_no,
      original_serial_no: values.original_serial_no,
      replaced_serial_no: values.replaced_serial_no,
      replaced_ph_rev: values.replaced_ph_rev,
      exchange_date: values.exchange_date,
    };
    setIsLoading(true);
    sendPrintHeadExchange(printHeadExchange);
  }

  function sendPrintHeadExchange(printHeadExchange) {
    const url = process.env.REACT_APP_APIURL + 'printhead/replace';
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(printHeadExchange));

    return axios.post(url, formData, {headers: authToken}).then((response) => {
      setIsLoading(false);
      setSuccessMsg(true);
    }).catch((error) => {
      setIsLoading(false);
      setErrorMsg(true);
    });
  }

  /* eslint-disable camelcase */
  return (
    <React.Fragment>
      <div className="text-white-50 card bg-yellow card-quicknav">
        <div className="card-body"
          onClick={() => {
            toggleModal();
          }}
          data-toggle="modal"
          data-target=".bs-example-modal-center"
          type="button">
          <div className="mb-4 text-primary card-title">Print Head exchange<br/> without guarantee</div>
          <i className="mdi mdi-arrow-right text-primary"></i>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <button
            type="button"
            onClick={() => {
              setModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Row>
            <Col md={1}>
              <div className="avatar-sm">
                <span
                  className="avatar-title rounded-circle bg-primary text-white">
                  <i className="bx bx-refresh bx-sm rotateInUpLeft"></i>
                </span>
              </div>
            </Col>
            <Col md={11}>
              <div className="mb-2">
                <h5 className="modal-title mt-0">Print Head Replacement</h5>
                <p>Exchange Print Heads without guarantee</p>
              </div>
              <div className="mb-3">
                <AvForm
                  className="form-horizontal mt-4"
                  model={model}
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <div className="mb-4">
                    <AvRadioGroup name="original_serial_no">
                      {
                        printer.print_heads.filter(
                            (print_head) => print_head.installed === 1,
                        ).map((print_head, index) =>
                          <div
                            className="mb-2"
                            key={'original_serial_no-' + index}>
                            <AvRadio
                              label={
                                <PrintheadStatus print_head={print_head}/>
                              }
                              value={print_head.serial_no}/>
                          </div>,
                        )
                      }
                    </AvRadioGroup>
                  </div>
                  <Row>
                    <Col md="8">
                      {(() => {
                        if (['gtx600', 'gtx600sb'].includes(printer.printer_type)) {
                          return (
                            <Printhead name="replaced_serial_no"
                              label="Serial number new Print head *"
                              example="6WA1234"
                              pattern="^[0-9]{1}[A-Z]{2}[0-9]+$"/>
                          );
                        } else if (printer.printer_type === 'wf1l640') {
                          return (
                            <Printhead name="replaced_serial_no"
                              label="Serial number new Print head *"
                              example="W1A1234"
                              pattern="^[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]+$"/>
                          );
                        }
                        return <Printhead name="replaced_serial_no"
                          label="Serial number new Print head *"/>;
                      })()}
                    </Col>
                    {['gtxpro', 'gtxprobulk', 'gtx600', 'gtx600sb'].includes(printer.printer_type) &&
                      <Col md="8">
                        <AvField
                          type="text"
                          name="replaced_ph_rev"
                          label="V.Rating *"
                          required
                        />
                      </Col>
                    }
                    {['wf1l640'].includes(printer.printer_type) &&
                      <Col md="8">
                        <AvField
                          type="text"
                          name="replaced_ph_rev"
                          label="V.Rank *"
                          required
                        />
                      </Col>
                    }
                    <Col md="8">
                      <AvField
                        name="exchange_date"
                        className="form-control"
                        label="Date of exchange *"
                        type="date"
                        max={moment().format('YYYY-MM-DD')}
                        required
                        errorMessage="Please choose a valid date."
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="8">
                      <p>If you want to replace a Print Head still in
                        warranty, please
                      <Link
                        className="link-highlight ml-1"
                        to={'/add-claim'}
                      >
                          add a Claim
                      </Link>.
                        A replaced Print Head receives 3 months additional
                        warranty.
                      </p>
                    </Col>
                    <Col md="4">
                      <div className="text-sm-right">
                        {isLoading ?
                          <Button
                            className="btn btn-yellow"
                            type="submit"
                            disabled={true}
                          >
                            <i className="bx bx-loader bx-spin font-size-16
                              align-middle mr-2"/>
                            Submit
                          </Button> :
                          <Button className="btn btn-yellow" type="submit">
                            Submit
                          </Button>
                        }
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          window.location.reload();
        }}
      >
        Print Head Replacement was submitted successfully.
      </SweetAlert> : null}
      {errorMsg ? <SweetAlert
        title="Request could not be submitted!"
        warning
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          setErrorMsg(false);
        }}
      >
        There was an error while submitting your request. Please try again or
        get in contact with our team.
      </SweetAlert> : null}
    </React.Fragment>
  );
};
/* eslint-enable camelcase */

export default ModalPrintheadExchange;
