// @ts-nocheck
import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Button, Col, Label, Row,
} from 'reactstrap';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {createLoadingSelector} from '../../../selectors/loading';
import {createErrorSelector} from '../../../selectors/error';
import {EDIT_MACHINE} from '../../../store/machine/actionTypes';
import {useHistory} from 'react-router-dom';
import {editMachine} from '../../../store/machine/actions';
import ListPrinters from '../../Lists/ListPrinters';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

interface MachineFormProps {
  machine: machine,
}

/* eslint-disable camelcase */
export const MachineForm: FC<MachineFormProps> = (
    {
      machine,
    }) => {
  const dispatch = useDispatch();

  const loadingSelector = useSelector(createLoadingSelector([EDIT_MACHINE]));
  const errorSelector = useSelector(createErrorSelector([EDIT_MACHINE]));

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (submitted) {
      setSuccessMsg(!loadingSelector && errorSelector.length === 0);
      setErrorMsg(!loadingSelector && errorSelector.length > 0);
    }
  }, [loadingSelector, errorSelector, submitted]);


  function handleSelectPrinter(selectedPrinter) {
    setSelectedPrinter(selectedPrinter);
  }

  function handleInvalidSubmit(event, values) {
    //    @TODO handle invalid submit
  }

  function handleValidSubmit(event, values) {
    setSubmitted(true);
    if (machine.id) {
      const editedMachine: EditAPImachine = {
        id: machine.id,
        printer_serial_no: selectedPrinter.printer_serial_no,
        installation_date: values.installation_date,
      };
      dispatch(editMachine(editedMachine));
    } else {
      handleInvalidSubmit(event, values);
    }
  }

  function handleDisconnect() {
    setSubmitted(true);
    const editedMachine: EditAPIMachine = {
      id: machine.id,
    };
    dispatch(editMachine(editedMachine));
  }

  return (
    <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
      handleValidSubmit(e, v);
    }}>
      {errorMsg ?
        <SweetAlert
          title="Error!"
          danger
          showConfirm={true}
          showCancel={false}
          confirmBtnBsStyle="danger"
          // closeOnConfirm={true}
          onConfirm={() => {
            setErrorMsg(false);
          }}
        >
          {errorMsg}{machine.id}
        </SweetAlert> : ''
      }
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          history.push('/machines');
        }}
      >
        The machine has been updated!
      </SweetAlert> : null}
      <Row className="align-items-center">
        {machine.printer &&
          <>
            <Col md="4">
              <Button
                disabled={loadingSelector}
                color="primary"
                onClick={handleDisconnect}
              >
                Disconnect from current printer
              </Button>
            </Col>
            <Col md="2">
              OR
            </Col>
          </>
        }
        <Col md="4">
          <Label htmlFor="printer-serialno" className="col-form-label"><strong>Printer SERIAL No.</strong></Label>
          <ListPrinters
            filter={['gtxpro', 'gtxprobulk']}
            onChange={(printer) => {
              handleSelectPrinter(printer);
            }}/>
          <Row>
            <Col md="12">
              <AvField
                name="installation_date"
                value=""
                className="form-control"
                placeholder="Installation Date of Machine"
                label="Installation Date of Machine"
                type="date"
                max={moment().format('YYYY-MM-DD')}
                validate={{required: {value: true}}}
                errorMessage="Provide a valid installation date."
              />
            </Col>
          </Row>
          <Button
            disabled={loadingSelector}
            type="submit"
            color="primary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </AvForm>
  );
};
/* eslint-enable camelcase */
