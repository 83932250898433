import {
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
} from './actionTypes';

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS_REQUEST,
});

export const getNotificationsSuccess = (data) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const getNotificationsError = (error) => {
  return {
    type: GET_NOTIFICATIONS_ERROR,
    payload: error,
  };
};
