// This is just for the Loading or Error Selector
export const GET_MACHINE = 'GET_MACHINE';

// Use these for Actions
export const GET_MACHINE_REQUEST = 'GET_MACHINE_REQUEST';
export const GET_MACHINE_SUCCESS = 'GET_MACHINE_SUCCESS';
export const GET_MACHINE_ERROR = 'GET_MACHINE_ERROR';

// This is just for the Loading or Error Selector
export const CREATE_MACHINE = 'CREATE_MACHINE';

// Use these for Actions
export const CREATE_MACHINE_REQUEST = 'CREATE_MACHINE_REQUEST';
export const CREATE_MACHINE_SUCCESS = 'CREATE_MACHINE_SUCCESS';
export const CREATE_MACHINE_ERROR = 'CREATE_MACHINE_ERROR';

// This is just for the Loading or Error Selector
export const EDIT_MACHINE = 'EDIT_MACHINE';

// Use these for Actions
export const EDIT_MACHINE_REQUEST = 'EDIT_MACHINE_REQUEST';
export const EDIT_MACHINE_SUCCESS = 'EDIT_MACHINE_SUCCESS';
export const EDIT_MACHINE_ERROR = 'EDIT_MACHINE_ERROR';

