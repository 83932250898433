// @flow
import {GET_USER_NOTIFICATIONS_SUCCESS} from './actionTypes';

const initialState = {

};

const UserNotifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_NOTIFICATIONS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default UserNotifications;
