import React, {FC} from 'react';
import {getLoggedInUser} from '../../../helpers/fakebackend_helper';
import {ROLE, RoleNameMap} from '../../Role/constants';
import {
  CLAIM_FINAL_CHECK_STATUS,
  CLAIM_INTERNAL_CHECK_STATUS,
  CLAIM_STATUS,
} from '../constants';
import {Badge} from 'react-bootstrap';

/* eslint-disable camelcase */
interface ClaimStatusProps {
    status: CLAIM_STATUS,
    final_check_status: CLAIM_FINAL_CHECK_STATUS,
    internal_check_status: CLAIM_INTERNAL_CHECK_STATUS,
    fontsize?: string
}

const ClaimStatus: FC<ClaimStatusProps> = (
    {
      status,
      final_check_status,
      internal_check_status,
      fontsize,
    }) => {
  const authUser = getLoggedInUser(); // @TODO get the user from selector
  const userRole = RoleNameMap.get(authUser.user.role);

  // @TODO maybe refactor to make it more readable?
  return (
    <>
      {status === CLAIM_STATUS.OPEN ?
        <Badge
          pill
          variant={'success'}
          className={fontsize + ' badge-soft-success'}
        >
          Open
        </Badge> : ''
      }
      {status === CLAIM_STATUS.IN_PROGRESS ?
        <Badge
          pill
          variant='warning'
          className={fontsize + ' badge-soft-warning'}
        >
          In Progress
        </Badge> : ''
      }
      {status === CLAIM_STATUS.FINAL_CHECK &&
      (final_check_status === CLAIM_FINAL_CHECK_STATUS.GRANTED) ?
        <Badge
          pill
          variant={'success'}
          className={fontsize + ' badge-soft-success'}
        >
          Guarantee granted
        </Badge> : ''
      }
      {status === CLAIM_STATUS.FINAL_CHECK &&
      final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED &&
      (userRole === ROLE.BROTHER_MANAGER ||
        userRole === ROLE.BROTHER_STAFF ||
        userRole === ROLE.BROTHER_SALES
      ) ?
        <Badge
          pill
          variant={'success'}
          className={fontsize + ' badge-soft-success'}
        >
          Internal granted
        </Badge> : ''
      }
      {status === CLAIM_STATUS.FINAL_CHECK &&
      final_check_status === CLAIM_FINAL_CHECK_STATUS.INTERNAL_GRANTED &&
      userRole === ROLE.DEALER_ENGINEER ?
        <Badge
          pill
          variant={'success'}
          className={fontsize + ' badge-soft-success'}
        >
          Guarantee granted
        </Badge> : ''
      }
      {status === CLAIM_STATUS.FINAL_CHECK &&
      final_check_status === CLAIM_FINAL_CHECK_STATUS.NOT_GRANTED ?
        <Badge
          pill
          variant={'danger'}
          className={fontsize + ' badge-soft-danger'}
        >
          Guarantee not granted
        </Badge> : ''
      }
      {(userRole === ROLE.BROTHER_MANAGER ||
        userRole === ROLE.BROTHER_STAFF ||
        userRole === ROLE.BROTHER_SALES) &&
      internal_check_status !== CLAIM_INTERNAL_CHECK_STATUS.NONE ?
        <Badge
          pill
          variant={'success'}
          className={fontsize + ' badge-soft-success'}
        >
          Internal checked
        </Badge> : ''
      }
    </>
  );
};
/* eslint-enable camelcase */

export default ClaimStatus;
