import axios from 'axios';
import authHeader from '../helpers/jwt-token-access/auth-token-header';

const getMachineFromBackend = (machineSerial) => {
  const url = process.env.REACT_APP_APIURL + 'machine/' + machineSerial;
  const authToken = authHeader();

  return axios.get(url, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((err) => {
    throw err[1];
  });
};

const createMachineInBackend = (machine) => {
  const url = process.env.REACT_APP_APIURL + 'machine';
  const authToken = authHeader();
  const formData = new FormData();
  if (machine.files) {
    machine.files.forEach((file) => {
      formData.append('file[' + file.target + ']', file);
    });
  }
  formData.append('data', JSON.stringify(machine));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    // if (response.status === 422 || response.status === 500)
    //     return response.data;
    return response.data;
  }).catch((error) => {
    // throw err[1];
    return error.response.data;
  });
};

const editMachineInBackend = (machine: EditAPIMachine) => {
  const url = process.env.REACT_APP_APIURL + 'machine/edit/' + machine.id;
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(machine));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
};

export {getMachineFromBackend, createMachineInBackend, editMachineInBackend};
