import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

// Account Redux states
import {REGISTER_USER} from './actionTypes';
import {registerUserSuccessful, registerUserFailed} from './actions';

// Include Both Helper File with needed methods
import {postJwtRegister} from '../../../helpers/fakebackend_helper';

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
/**
 * register user
 */
function* registerUser({payload: {user}}) {
  try {
    const response = yield call(
        postJwtRegister,
        process.env.REACT_APP_APIURL + 'auth/register',
        user,
    );
    yield put(registerUserSuccessful(response));
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

/**
 * Watcher
 */
export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

/**
 * Saga
 */
function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
