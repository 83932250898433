import React from 'react';
import {AvRadio, AvRadioGroup} from 'availity-reactstrap-validation';
import {Label} from 'reactstrap';

const Gtx600sbServices = (props) => {
  const selectedPrinter = props.selectedPrinter;

  return (
    <>
      <Label htmlFor="service_type" className="col-form-label">Choose a
                service *</Label>
      <AvRadioGroup
        name="service_type"
        required
        validate={{required: {value: true}}}
        errorMessage="Please choose a valid option."
      >
        {
          !selectedPrinter || (selectedPrinter &&
            selectedPrinter.services.find(
                (service) => [
                  '50k', '100k', '150k', '200k',
                ].includes(service.service_type))) ?
            <div
              className={'service-option mb-2 form-control is-disabled'}>
              <AvRadio
                customInput
                label="50k Service"
                value="50k"
                disabled={true}
              />
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002428</span></p>
            </div> :
            <div
              className={'service-option mb-2 form-control'}>
              <AvRadio customInput label="50k Service" value="50k"/>
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002428</span></p>
            </div>
        }
        {
          !selectedPrinter ||
          (selectedPrinter && selectedPrinter.services.find(
              (service) => [
                '100k', '150k', '200k',
              ].includes(service.service_type))) ?
            <div
              className={'service-option mb-2 form-control is-disabled'}>
              <AvRadio
                customInput
                label="100k Service"
                value="100k"
                disabled={true}
              />
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002429</span></p>
            </div> :
            <div
              className={'service-option mb-2 form-control'}>
              <AvRadio
                customInput
                label="100k Service"
                value="100k"
              />
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002429</span></p>
            </div>
        }
        {
          !selectedPrinter ||
          (selectedPrinter && selectedPrinter.services.find(
              (service) => [
                '150k', '200k',
              ].includes(service.service_type))) ?
            <div
              className={'service-option mb-2 form-control is-disabled'}>
              <AvRadio
                customInput
                label="150k Service"
                value="150k"
                disabled={true}
              />
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">
                  N40002428
                </span>
              </p>
            </div> :
            <div
              className={'service-option mb-2 form-control'}>
              <AvRadio customInput label="150k Service" value="150k"/>
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002428</span></p>
            </div>
        }
        {
          !selectedPrinter ||
          (selectedPrinter && selectedPrinter.services.find(
              (service) => ['200k'].includes(service.service_type))) ?
            <div
              className={'service-option mb-2 form-control is-disabled'}>
              <AvRadio
                customInput
                label="200k Service"
                value="200k"
                disabled={true}
              />
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002430</span></p>
            </div> :
            <div
              className={'service-option mb-2 form-control'}>
              <AvRadio customInput label="200k Service" value="200k"/>
              <p className="mb-0 pl-4">
                DEALER RESPONSIBILTY. GTX600SB SPARE PART KIT
                <br/>
                <span
                  className="text-yellow">N40002430</span></p>
            </div>
        }
      </AvRadioGroup>
    </>
  );
};

export default Gtx600sbServices;
