// @flow
import {GET_DEALERS_SUCCESS} from './actionTypes';

const initialState = {

};

const Dealers = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEALERS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default Dealers;
