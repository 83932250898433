// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {
  GET_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  EDIT_CUSTOMER_REQUEST,
} from './actionTypes';
import {
  createCustomerError,
  createCustomerSuccess, editCustomerError, editCustomerSuccess,
  getCustomerError,
  getCustomerSuccess,

} from './actions';
import {
  getCustomerFromBackend,
  createCustomerInBackend,
  editCustomerInBackend,
} from '../../api/customer';

/**
 * Retrieving Customers
 * @param {*} param0
 */
function* getCustomer({payload: customerId}) {
  try {
    const data = yield call(getCustomerFromBackend, customerId);
    yield put(getCustomerSuccess(data));
  } catch (error) {
    yield put(getCustomerError(error));
  }
}

/**
 * Create Customer
 * @param {*} param0
 */
function* createCustomer({payload: customer}) {
  try {
    const data = yield call(createCustomerInBackend, customer);
    yield put(createCustomerSuccess(data));
  } catch (error) {
    yield put(createCustomerError(error));
  }
}

/**
 * Edit Customer
 * @param {*} param0
 */
function* editCustomer({payload: customer}) {
  try {
    const data = yield call(editCustomerInBackend, customer);
    yield put(editCustomerSuccess(data));
  } catch (error) {
    yield put(editCustomerError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_CUSTOMER_REQUEST, getCustomer);
  yield takeEvery(CREATE_CUSTOMER_REQUEST, createCustomer);
  yield takeEvery(EDIT_CUSTOMER_REQUEST, editCustomer);
}

/**
 * Saga
 */
function* CustomerSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default CustomerSaga;
