import React, {FC, useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './datatables.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {getEngineersSelector} from '../../selectors/engineer';
import {EngineerList} from '../../components/Engineer/EngineerList';
import {getEngineers} from '../../store/engineers/actions';
import {ROLE, RoleNameMap} from '../../components/Role/constants';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_ENGINEERS} from '../../store/engineers/actionTypes';

const EngineersOverview: FC = () => {
  const engineers = useSelector(getEngineersSelector);
  const dispatch = useDispatch();
  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  const engineersLoadingSelector = useSelector(
      createLoadingSelector([GET_ENGINEERS]),
  );
  const [engineersLoading, setEngineersLoading] = useState(true);

  useEffect(() => {
    dispatch(getEngineers());
  }, [dispatch]);

  useEffect(() => {
    setEngineersLoading(engineersLoadingSelector);
  }, [engineersLoadingSelector]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Members" breadcrumbItem="Overview"/>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {authUser.user && [
                  ROLE.BROTHER_MANAGER, ROLE.BROTHER_STAFF,
                ].includes(userRole) ?
                  <div className="text-sm-right">
                    <Link to={{
                      pathname: '/add-member',
                    }}>
                      <Button
                        type="button"
                        color="yellow"
                        className="btn-rounded waves-effect waves-light
                        mb-2 mr-2"
                      >
                        <i className="mdi mdi-plus mr-1"/>
                          Add New Member
                      </Button>
                    </Link>
                  </div> : ''}
                <EngineerList
                  engineers={engineers}
                  isLoading={engineersLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default EngineersOverview;
