import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from 'reactstrap';
import {createLoadingSelector} from '../../../selectors/loading';
import {EDIT_CUSTOMER} from '../../../store/customer/actionTypes';
import {LoadingIndicator} from '../../LoadingIndicator';
import {CustomerForm} from '../CustomerForm';
import {urlChecker, urlProtocolAdder} from '../../../helpers/Url';

interface CustomerCardProps {
    customer?: FullCustomer,
    isLoading: boolean
}

export const CustomerCard: FC<CustomerCardProps> = ({customer, isLoading}) => {
  const [modal, setModal] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);

  const loadingSelector = useSelector(createLoadingSelector([EDIT_CUSTOMER]));

  useEffect(() => {
    setModalIsLoading(loadingSelector);
  }, [loadingSelector]);

  return (
    <Card>
      <CardBody>
        <Media>
          <Media body>
            <h4 className="card-title">Customer</h4>
            {!isLoading && customer && <div>
              <h5 className="">{customer.name}</h5>
              {customer.additional_address &&
                <p className="mb-0">{customer.additional_address}</p>
              }
              <p className="mb-1">
                {customer.street} {customer.street_no}
                <br/>
                {customer.zip_code} {customer.city}
                <br/>
                {customer.country}
              </p>
              <p className="mb-1"><i className='bx bx-envelope mr-2 mt-2'/> <a
                href={'mailto:' + customer.email}>{customer.email}</a></p>
              <p className="mb-1"><i className='bx bx-link mr-2'/>
                <a target="_blank" rel="noopener noreferrer"
                  href={urlChecker(customer.www) ? customer.www :
                    urlProtocolAdder(customer.www)}
                >
                  {customer.www}
                </a>
              </p>
              <p className="mb-1"><i className='bx bx-phone mr-2'/>
                {customer.phone}
              </p>
            </div>}
            {isLoading && <LoadingIndicator />}
          </Media>
          <div className="avatar-sm ">
            <IconButton
              aria-label="edit"
              onClick={() => setModal(true)}
              disabled={isLoading}
            >
              <EditIcon/>
            </IconButton>
            <Modal
              size="lg"
              isOpen={modal}
              role="dialog"
              autoFocus={true}
              centered={true}
              className="customerEditModal"
              toggle={() => {
                setModal(!modal);
              }}
            >
              <div className="modal-content">
                <ModalHeader toggle={() => {
                  setModal(!modal);
                }}>
                  {'Edit Customer'} {modalIsLoading && <LoadingIndicator />}
                </ModalHeader>
                <ModalBody>
                  {customer && <CustomerForm customer={customer}/>}
                </ModalBody>

              </div>
            </Modal>
          </div>
        </Media>
      </CardBody>
      <CardBody className="border-top">
        {!isLoading && customer?.dealer && <div className="d-flex">
          <div className="text-truncate">
            <h5 className="font-size-14">Current Dealer</h5>
            <p className="mb-1">{customer.dealer.name}</p>
            <p className="mb-0">{customer.dealer.country}</p>
          </div>
        </div>}
      </CardBody>
    </Card>
  );
};
