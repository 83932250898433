/**
 * @description selector to get all customers.
 * @param {*} state
 * @return {*}
 */
export const getCustomersSelector: (state: any) => OverviewCustomer[] =
    (state) => ((state.Customers && state.Customers.data) ?
      state.Customers.data : []
    );

export const getCustomerByIdSelector = (state: any, id: number) =>
  (state.Customer && state.Customer.data && state.Customer.data.id === id) ?
    state.Customer.data : undefined;
