import React, {useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  CardBody,
  Button, Input,
} from 'reactstrap';

import {Link, useHistory} from 'react-router-dom';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import ListCustomers from '../../components/Lists/ListCustomers';

const PrinterOvertake = (props) => {
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [serialStatus, setSerialStatus] = useState(null);
  const [overtakeStatus, setOvertakeStatus] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  function handleChangePrinterSerial(printerSerialNo) {
    if (printerSerialNo.length === 8) {
      const url = process.env.REACT_APP_APIURL + 'printer/checkovertake';
      const authToken = authHeader();

      const formData = new FormData();
      formData.append('serial', printerSerialNo);

      return axios.post(url, formData, {headers: authToken}).then(
          (response) => {
            setSelectedPrinter(response.data.printer);
            setSerialStatus(true);
            setOvertakeStatus(true);
          }).catch((error) => {
        if (error.response.status === 409) {
          setSelectedPrinter(error.response.data.printer);
          setSerialStatus(true);
          setOvertakeStatus(false);
        } else if (error.response.status === 404) {
          setSelectedPrinter(null);
          setSerialStatus(false);
          setOvertakeStatus(false);
        }
      });
    }
  }

  function handleValidSubmit(event, values) {
    const overtake = {
      printer_serial_no: selectedPrinter.printer_serial_no,
      new_customer_id: selectedCustomer ?
        selectedCustomer.id : selectedPrinter.customer.id,
    };

    setIsLoading(true);
    addOvertake(overtake);
  }

  function addOvertake(service) {
    const url = process.env.REACT_APP_APIURL + 'overtake/printer';
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(service));

    return axios.post(url, formData, {headers: authToken}).then((response) => {
      setIsLoading(false);
      setSuccessMsg(true);
    }).catch((error) => {
      setIsLoading(false);
      setErrorMsg(true);
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs title="Overtake Printer"/>

          {errorMsg ? <SweetAlert
            title="Request could not be submitted!"
            warning
            showConfirm={true}
            showCancel={false}
            confirmBtnBsStyle="yellow"
            onConfirm={() => {
              setErrorMsg(false);
            }}
          >
              There was an error while submitting your request. Please
              try again or get in contact with our team.
          </SweetAlert> : null}
          {successMsg ? <SweetAlert
            title="Good job!"
            success
            showConfirm={true}
            showCancel={false}
            confirmBtnBsStyle="yellow"
            onConfirm={() => {
              setSuccessMsg(false);
              history.push('/dashboard');
            }}
          >
                        The overtake has been submitted.
          </SweetAlert> : ''
          }

          <Row>
            <Col xs="12">
              <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}>
                <Card>
                  <CardBody>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="printer-serialno" md="3"
                        className="col-form-label">
                          Printer SERIAL No.*
                        <br/>
                      </Label>
                      <Col md="9">
                        <AvField
                          name="request_serial"
                          className="form-control"
                          placeholder="Printer Serial No."
                          type="text"
                          validate={
                            {
                              required: {value: true},
                              pattern: {value: '^[A-Z][0-9]+$'},
                              minLength: {value: 8},
                              maxLength: {value: 8},
                              async: (value, ctx, input, cb) => {
                                cb(serialStatus);
                              },
                            }
                          }
                          onChange={(e) => handleChangePrinterSerial(
                              e.target.value,
                          )}
                          onInput={(e) => e.target.value = (
                            '' + e.target.value
                          ).toUpperCase()}
                          errorMessage="Please provide a valid serial number."
                          helpMessage="Example: A1234567."
                        />
                        {serialStatus === true && overtakeStatus === false &&
                          <p>This printer is not yours. You can request a
                              takeover. Click on the
                              &quot;Submit transfer&quot; button. We
                              will inform you by email.
                          </p>}
                        {serialStatus === false && overtakeStatus === false &&
                          <p>This serial number does not exist. Please
                            <Link
                              className="link-highlight"
                              to={'/add-printer'}
                            >
                                  add a new printer
                            </Link>.
                          </p>}
                      </Col>
                    </FormGroup>
                  </CardBody>
                </Card>
                {selectedPrinter && overtakeStatus &&
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="name">Customer</Label>
                            <Input id="name" name="name" type="text"
                              value={
                                selectedPrinter && selectedPrinter.customer.name
                              }
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="dealer">Dealer</Label>
                            <Input id="dealer" name="dealer"
                              value={
                                selectedPrinter && selectedPrinter.dealer.name
                              }
                              type="text"
                              className="form-control"
                              disabled="disabled"/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                }
                {selectedPrinter && overtakeStatus &&
                  <Card>
                    <CardBody>
                      <Row>
                        <Label htmlFor="printer-serialno" md="2"
                          className="col-form-label font-weight-bold">
                            New Customer *
                          <br/>
                        </Label>
                        <Col md="10">
                          <ListCustomers
                            required={true}
                            onChange={(customer) => {
                              setSelectedCustomer(customer);
                            }}/>
                        </Col>
                      </Row>
                      <p>
                          Customer Missing? Please
                        <Link
                          className="link-highlight"
                          to="/add-customer"
                        >
                              add customer
                        </Link>
                          first
                      </p>
                    </CardBody>
                  </Card>
                }
                <Row className="my-4">
                  <Col sm="6">
                    <Link
                      to="/dashboard"
                      className="btn text-muted d-none d-sm-inline-block
                      btn-link"
                    >
                      <i className="mdi mdi-arrow-left mr-1"/>
                        Back to Dashboard
                    </Link>
                  </Col>
                  <Col sm="6">
                    <div className="text-sm-right">
                      {isLoading ?
                        <Button
                          className="btn btn-yellow"
                          type="submit"
                          disabled={true}
                        >
                          <i className="bx bx-loader bx-spin font-size-16
                          align-middle mr-2"/>
                            Submit Transfer
                        </Button> :
                        <Button className="btn btn-yellow" type="submit">
                          <i className="mdi mdi-plus-thick mr-1"/>
                            Submit Transfer
                        </Button>
                      }
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrinterOvertake;
