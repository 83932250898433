// @ts-nocheck
import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Row,
  Col,
  Button,
  Label,
} from 'reactstrap';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import ListCountries from '../../Lists/ListCountries';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useHistory} from 'react-router-dom';
import {editCustomer} from '../../../store/customer/actions';
import {createLoadingSelector} from '../../../selectors/loading';
import {EDIT_CUSTOMER} from '../../../store/customer/actionTypes';
import {createErrorSelector} from '../../../selectors/error';

interface CustomerFormProps {
    customer: FullCustomer,
}

/* eslint-disable camelcase */
export const CustomerForm: FC<CustomerFormProps> = (
    {
      customer: {
        id,
        name,
        additional_address,
        contact_name,
        street,
        street_no,
        zip_code,
        city,
        country,
        phone,
        email,
        www,
      },
    }) => {
  const dispatch = useDispatch();

  const loadingSelector = useSelector(
      createLoadingSelector([EDIT_CUSTOMER]),
  );
  const errorSelector = useSelector(createErrorSelector([EDIT_CUSTOMER]));

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({name: ''});

  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (submitted) {
      setSuccessMsg(!loadingSelector && errorSelector.length === 0);
      setErrorMsg(!loadingSelector && errorSelector.length > 0);
    }
  }, [loadingSelector, errorSelector, submitted]);

  function handleSelectCountry(selectedCountry) {
    setSelectedCountry(selectedCountry);
  }

  function handleInvalidSubmit(event, values) {
    //    @TODO handle invalid submit
  }

  function handleValidSubmit(event, values) {
    setSubmitted(true);
    if (id) {
      const editedCustomer: EditAPICustomer = {
        id: id,
        contact_name: values.contact_name,
        additional_address: values.additional_address,
        street: values.street,
        street_no: values.street_no,
        zip_code: values.zip_code,
        city: values.city,
        country: selectedCountry.name,
        phone: values.phone,
        email: values.email,
        www: values.www,
        // logo: values.logo,
      };
      dispatch(editCustomer(editedCustomer));
    } else {
      handleInvalidSubmit(event, values);
    }
  }

  return (
    <AvForm
      className="form-horizontal"
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, v);
      }}
      onInvalidSubmit={(e, v) => {
        handleInvalidSubmit(e, v);
      }}
    >
      {errorMsg ?
        <SweetAlert
          title="Error!"
          danger
          showConfirm={true}
          showCancel={false}
          confirmBtnBsStyle="danger"
          // closeOnConfirm={true}
          onConfirm={() => {
            setErrorMsg(false);
          }}
        >
          {errorMsg}
        </SweetAlert> : ''
      }
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          history.push('/customers');
        }}
      >
                    The customer has been updated!
      </SweetAlert> : null}
      <Row>
        <Col md="6">
          <AvField
            name="name"
            value={name || ''}
            className="form-control"
            placeholder="Company name *"
            label="Company"
            type="text"
            disabled={true}
          />
        </Col>
        <Col md="4">
          <AvField
            name="contact_name"
            value={contact_name || ''}
            className="form-control"
            placeholder="Contact name *"
            label="Contact"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a contact name."
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <AvField
            name="additional_address"
            value={additional_address || ''}
            className="form-control"
            placeholder="Additional Address (optional)"
            label="Additional Address"
            type="text"
            validate={{required: {value: false}}}
            errorMessage="Please provide a valid additional address."
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <AvField
            name="street"
            value={street || ''}
            className="form-control"
            placeholder="Street *"
            label="Street"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a valid street."
          />
        </Col>
        <Col md="2">
          <AvField
            name="street_no"
            value={street_no || ''}
            className="form-control"
            placeholder="Street No *"
            label="Street No"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a valid street no."
          />
        </Col>
        <Col md="2">
          <AvField
            name="zip_code"
            value={zip_code || ''}
            className="form-control"
            placeholder="Zip Code *"
            label="Zip Code"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a valid zip code."
          />
        </Col>
        <Col md="3">
          <AvField
            name="city"
            value={city || ''}
            className="form-control"
            placeholder="City *"
            label="City"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a valid city."
          />
        </Col>
        <Col md="3">
          <Label htmlFor="country"
            className="col-form-label">Country</Label>
          <ListCountries
            initialSelect={country}
            onChange={(countryChange) => {
              handleSelectCountry(countryChange);
            }}
            required={true}
          />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <AvField
            name="phone"
            value={phone || ''}
            className="form-control"
            placeholder="Phone *"
            label="Phone"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a valid phone number."
          />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <AvField
            name="email"
            value={email || ''}
            className="form-control"
            placeholder="Email Address*"
            label="Email"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a valid email address."
          />
        </Col>
        <Col md="4">
          <AvField
            name="www"
            value={www || ''}
            className="form-control"
            placeholder="Web Address *"
            label="Web Address"
            type="text"
            validate={{required: {value: true}}}
            errorMessage="Please provide a web address."
          />
        </Col>
      </Row>
      <Button
        disabled={loadingSelector}
        type="submit"
        color="primary"
      >Save</Button>
    </AvForm>
  );
};
/* eslint-enable camelcase */
