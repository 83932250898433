import React from 'react';

import {Row, Col, Card, CardBody} from 'reactstrap';

import {getLoggedInUser} from '../../helpers/fakebackend_helper';

const WelcomeComp = (props) => {
  const authUser = getLoggedInUser();

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-dark position-relative">
          <div className="text-white p-3">
            <h5 className="text-yellow">Brother TECNET</h5>
            <p>
              {process.env.REACT_APP_REALM.toUpperCase() + ' '}
              Dealer Service Platform
            </p>
          </div>
        </div>
        <CardBody>
          <Row>
            {/* <Col sm="12">*/}
            {/*   <div className="avatar-md profile-user-wid mb-4">*/}
            {/*       <img */}
            {/*         src={avatar1} */}
            {/*         alt="" */}
            {/*         className="img-thumbnail rounded-circle"*/}
            {/*       />*/}
            {/*   </div>*/}
            {/* </Col>*/}

            <Col sm="12">
              <Row>
                <Col xs="7">
                  <h5 className="font-size-18 text-truncate">
                    {authUser.user.firstname + ' ' + authUser.user.lastname}
                  </h5>
                  <p
                    className="mb-0 text-yellow text-truncate font-size-11
                    font-weight-bold"
                  >
                    {authUser.user.dealer_name}
                  </p>
                  <p className="mb-0 text-yellow text-truncate font-size-11
                  font-weight-bold"
                  >
                    {authUser.user.role.charAt(0).toUpperCase() +
                        authUser.user.role.slice(1)}
                  </p>
                </Col>
                {/* <Col xs="5">*/}
                {/*  <div className="mt-4">*/}
                {/*    <Link*/}
                {/*      to=""*/}
                {/*      className="btn btn-primary waves-effect*/}
                {/*          waves-light btn-sm"*/}
                {/*    >*/}
                {/*            View Profile*/}
                {/*      <i className="mdi mdi-arrow-right ml-1"/>*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/* </Col>*/}
              </Row>
            </Col>

            {/* <Col sm="8">*/}
            {/*  <div className="pt-4">*/}
            {/*    <Row>*/}
            {/*      <Col xs="6">*/}
            {/*        <h5 className="font-size-15">125</h5>*/}
            {/*        <p className="text-muted mb-0">Projects</p>*/}
            {/*      </Col>*/}
            {/*      <Col xs="6">*/}
            {/*        <h5 className="font-size-15">$1245</h5>*/}
            {/*        <p className="text-muted mb-0">Revenue</p>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*    <div className="mt-4">*/}
            {/*      <Link*/}
            {/*        to=""*/}
            {/*        className="btn btn-primary waves-effect w*/}
            {/*              aves-light btn-sm"*/}
            {/*      >*/}
            {/*                View Profile*/}
            {/*        <i className="mdi mdi-arrow-right ml-1"/>*/}
            {/*      </Link>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/* </Col>*/}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
