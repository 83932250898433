import React, {FC, useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import './datatables.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getMachines,
} from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {
  getMachinesSelector,
} from '../../selectors/machine';
import {MachineList} from '../../components/Machine/MachineList';
import {ROLE, RoleNameMap} from '../../components/Role/constants';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_MACHINES} from '../../store/machines/actionTypes';

const MachinesOverview: FC = () => {
  const allMachines = useSelector(getMachinesSelector);
  const dispatch = useDispatch();
  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  const [machines, setMachines] = useState<Machine[]>(allMachines);
  const machinesLoadingSelector = useSelector(
      createLoadingSelector([GET_MACHINES]),
  );
  const [machinesLoading, setmachinesLoading] = useState(true);

  useEffect(() => {
    dispatch(getMachines());
  }, [dispatch]);

  // Could be done in the other useEffect, but when Filters are used
  // separate are needed
  useEffect(() => {
    setMachines(allMachines);
  }, [allMachines]);

  useEffect(() => {
    setmachinesLoading(machinesLoadingSelector);
  }, [machinesLoadingSelector]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Machines" breadcrumbItem="Overview"/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {[
                    ROLE.BROTHER_MANAGER,
                    ROLE.BROTHER_STAFF,
                    ROLE.DEALER_ENGINEER,
                  ].includes(userRole) &&
                    <>
                      <div className="text-sm-right">
                        <Link to={{pathname: '/add-machine'}}>
                          <Button
                            type="button"
                            color="yellow"
                            className="btn-rounded waves-effect waves-light
                            mr-2"
                          >
                            <i className="mdi mdi-plus mr-1"/>
                              Add New Machine
                          </Button>
                        </Link>
                      </div>
                    </>
                  }
                  <MachineList
                    machines={machines}
                    isLoading={machinesLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MachinesOverview;

