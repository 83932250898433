// @flow
import {GET_NOTIFICATIONS_SUCCESS} from './actionTypes';

const initialState = {

};

const Notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default Notifications;
