import React from 'react';
import {Row, Col, Alert, Card, CardBody, Container, Button} from 'reactstrap';

// Redux
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';

// action
import {userForgetPassword} from '../../store/actions';

// import images
import logoImg from '../../assets/images/logo-brother.svg';

const ForgetPasswordPage = (props) => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history);
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <Card className="overflow-hidden">
                <div className="bg-dark mb-4 p-4">
                  <Row>
                    <Col className="col-7">
                      <div className="px-4">
                        <h3 className="text-yellow mb-0">Brother TECNET</h3>
                        <p className="text-white">
                          {process.env.REACT_APP_REALM.toUpperCase() + ' '}
                          Dealer Service Platform
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-center text-right">
                      <div className="px-4">
                        <img src={logoImg} alt="" className="img-fluid"/>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  <div className="p-2">

                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{marginTop: '13px'}}>
                        {props.forgetError}
                      </Alert>
                                        ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{marginTop: '13px'}}>
                        {props.forgetSuccessMsg}
                      </Alert>
                                        ) : null}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          {props.loading ?
                            <Button
                              className="btn btn-dark btn-block waves-effect
                              waves-light"
                              type="submit" disabled={true}>
                              <i className="bx bx-loader bx-spin font-size-16
                              align-middle mr-2"/>
                                Reset
                            </Button> :
                            <Button
                              className="btn btn-dark btn-block waves-effect
                              waves-light"
                              type="submit">
                                Reset
                            </Button>
                          }
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center font-size-13">
                <p>
                    Already have an account ?{' '}
                  <Link
                    to="/login"
                    className="font-weight-bold text-yellow"
                  >
                    {' '}
                      Login
                  </Link>
                  {' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {forgetError, forgetSuccessMsg, loading} = state.ForgetPassword;
  return {forgetError, forgetSuccessMsg, loading};
};

export default withRouter(
    connect(mapStatetoProps, {userForgetPassword})(ForgetPasswordPage),
);
