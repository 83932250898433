import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Container,
  Row,
  Col, CardBody,
  Card, Table, Button,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getMachine,
} from '../../store/actions';
import {useLocation} from 'react-router-dom';
import {getMachineByIdSelector} from '../../selectors/machine';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_MACHINE} from '../../store/machine/actionTypes';
import {MachineCard} from '../../components/Machine/MachineCard';
import Moment from 'react-moment';
import ClaimsTableOld from '../Claims/claims-table_old';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import MachineHistory from '../Machines/machine-history';
import ModalMachineAddFile from '../../components/Modals/modal-machineaddfile';

const MachineShow: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const machineId = location.pathname.split('/').pop() || 0;

  const machineSelector = useSelector(
      (state) => getMachineByIdSelector(state, +machineId),
  );

  const machineLoadingSelector = useSelector(
      createLoadingSelector([GET_MACHINE]),
  );

  const [machineLoading, setmachineLoading] = useState(true);
  const [machine, setmachine] = useState<Machine>();

  const authUser = getLoggedInUser();

  useEffect(() => {
    dispatch(getMachine(+machineId));
  }, [dispatch]);

  useEffect(() => {
    setmachine(machineSelector);

    machine && machine.files && machine.files.forEach((element) => {
      if (element.extension === 'pdf') {
        element['iconClass'] = 'mdi-file-pdf';
      } else if (element.extension === 'jpg') {
        element['iconClass'] = 'mdi-file-image';
      } else {
        element['iconClass'] = 'mdi-file';
      }
    });
  }, [machineSelector]);

  useEffect(() => {
    setmachineLoading(machineLoadingSelector);
  }, [machineLoadingSelector]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          parent="/machines"
          title="Machine"
          breadcrumbItem={machine && machine.machine_serial_no}
        />
        <Row>
          <Col xl="4">
            <MachineCard
              machine={machine}
              isLoading={machineLoading}
            />
            <MachineHistory machine={machine}/>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">File Manager</h4>
                <ModalMachineAddFile machine={machine}/>
                <div className="table-responsive">
                  <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Date modified</th>
                        <th>Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        machine && machine.files && machine.files.map((file, key) =>
                          <tr key={'_file_' + key}>
                            <td>
                              <a href={file.link} target="_blank"
                                rel="noopener noreferrer">
                                {
                                file.is_restricted === '1' ? <i
                                  className={'mdi mdi-lock font-size-16 align-middle ' +
                                  'text-danger mr-2'} title={'restricted file'}
                                /> : ''
                                }
                                <i
                                  className={'mdi ' + file.iconClass +
                                ' font-size-16 align-middle ' +
                                'text-primary mr-2'}
                                />
                                {
                                file.name.length > 5 ?
                                  `${file.name.substring(0, 10)}...` :
                                  file.name
                                }
                              </a>
                            </td>
                            <td>
                              <Moment
                                format="DD-MM-YYYY, HH:mm"
                              >
                                {file.created_at}
                              </Moment>
                            </td>
                            <td>
                              {formatBytes(file.size)}
                            </td>
                          </tr>,
                        )
                      }
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl="8">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Information</h4>
                <Row>
                  {machine && machine.customer ?
                    <Col lg="4">
                      <div className="border p-3 rounded full-height">
                        <div className="box-icon font-size-16">
                          <Link to={'/customer/' + machine.customer.id}>
                            <i className='bx bxs-user-circle'/>
                          </Link>
                        </div>
                        <div>
                          <h6>Customer</h6>
                          <h5>{machine.customer.name}</h5>
                          <p className="mb-2">
                            {machine.customer.street}<br/>
                            {machine.customer.zip_code} {machine.customer.city}
                            <br/>
                            {machine.customer.country}
                          </p>
                        </div>
                      </div>
                    </Col> : ''
                  }
                  {machine && machine.dealer ?
                    <Col lg="4">
                      <div className="border p-3 rounded full-height">
                        <div>
                          <h6>Current Dealer</h6>
                          <h5>{machine.dealer.name}</h5>
                          <p className="mb-2">
                            {machine.dealer.street}<br/>
                            {machine.dealer.zip_code} {machine.dealer.city}
                            <br/>
                            {machine.dealer.country}
                          </p>
                        </div>
                      </div>
                    </Col> : ''
                  }
                  {machine && machine.printer ?
                    <Col lg="4">
                      <div className="border p-3 rounded full-height">
                        <div className="box-icon font-size-16">
                          <Link to={'/printer/' + machine.printer.id}>
                            <i className='bx bxs-printer'/>
                          </Link>
                        </div>
                        <div>
                          <h6>Printer</h6>
                          <div className="d-flex mb-1">
                            <span
                              className="badge badge-dark font-size-14
                                text-yellow align-self-center"
                            >
                                SN
                            </span>
                            <span
                              className="ml-2 text-truncate font-size-18
                                text-yellow font-weight-bold"
                            >
                              {machine.printer.printer_serial_no}
                            </span>
                          </div>
                        </div>
                        {machine.printer.ink_supply_serial &&
                        <div className="d-flex mb-1">
                          <span
                            className="badge badge-dark font-size-14
                                text-yellow align-self-center">
                                ISN
                          </span>
                          <span
                            className="ml-2 text-truncate
                                font-size-18 text-yellow font-weight-bold">
                            {machine.printer.ink_supply_serial}
                          </span>
                        </div>
                        }
                        <div className="d-flex mb-3">
                          <i className='bx bx-power-off bx-xs mr-2'/>
                          <span className="text-truncate font-size-12">
                            <Moment
                              format="DD-MM-YYYY"
                            >
                              {machine.printer.installation_date}
                            </Moment>
                          </span>
                        </div>
                      </div>
                    </Col> : ''
                  }
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {[
                  'manager', 'staff', 'engineer',
                ].includes(authUser.user.role) &&
                <>
                  <div className="text-sm-right">
                    <Link to={{
                      pathname: '/add-claim',
                    }}>
                      <Button
                        type="button"
                        color="yellow"
                        className="btn-rounded waves-effect waves-light
                                mr-2">
                        <i
                          className="mdi mdi-plus mr-1"/>
                                Add New Claim
                      </Button>
                    </Link>
                  </div>
                </>
                }
                {
                  machine && machine.printer && machine.printer.claims &&
                  <ClaimsTableOld
                    claims={machine.printer.claims
                    }/>
                }

                {/* @TODO delete this? */}
                {/* <ClaimsTable*/}
                {/*    claims={printer?.claims || []}*/}
                {/* />*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MachineShow;
