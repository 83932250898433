import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
        } else {
          localStorage.removeItem('authUser');
        }
      });
    }
  }

  /* eslint-disable no-invalid-this */
  /**
   * Registers the user with given details
   * @param {string} email
   * @param {string} password
   *
   * @return {*}
   */
 registerUser = (email, password) => {
   return new Promise((resolve, reject) => {
     firebase
         .auth()
         .createUserWithEmailAndPassword(email, password)
         .then(
             (user) => {
               resolve(firebase.auth().currentUser);
             },
             (error) => {
               reject(this._handleError(error));
             },
         );
   });
 };

  /**
   * Registers the user with given details
   * @param {string} email
   * @param {string} password
   *
   * @return {*}
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(
              (user) => {
                resolve(firebase.auth().currentUser);
              },
              (error) => {
                reject(this._handleError(error));
              },
          );
    });
  };

  /**
   * Login user with given details
   * @param {string} email
   * @param {string} password
   *
   * @return {*}
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(
              (user) => {
                resolve(firebase.auth().currentUser);
              },
              (error) => {
                reject(this._handleError(error));
              },
          );
    });
  };

  /**
   * forget Password user with given details
   * @param {string} email
   *
   * @return {*}
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
          .auth()
          .sendPasswordResetEmail(email, {
            url: window.location.protocol + '//' + window.location.host +
              '/login',
          })
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
    });
  };

  /**
   * Logout the user
   * @return {*}
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
          .auth()
          .signOut()
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
    });
  };
  /* eslint-enable no-invalid-this */

  setLoggeedInUser = (user) => {
    localStorage.setItem('authUser', JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   * @return {*}
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem('authUser')) return null;
    return JSON.parse(localStorage.getItem('authUser'));
  };

  /**
   * Handle the error
   * @param {*} error
   * @return {*}
   */
  _handleError(error) {
    // var errorCode = error.code;
    const errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 * @return {*}
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 * @return {*}
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export {initFirebaseBackend, getFirebaseBackend};
