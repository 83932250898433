// @flow
import {
  GET_PRINTERS_SUCCESS,
} from './actionTypes';

const initialState = {

};

const Printers = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRINTERS_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default Printers;
