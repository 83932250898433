import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dropdown, DropdownToggle, DropdownMenu, Row, Col} from 'reactstrap';
import SimpleBar from 'simplebar-react';

import {
  getUserNotificationsSelector,
} from '../../../selectors/user_notification';
import {getUserNotifications} from '../../../store/user_notifications/actions';
import {
  setUserNotificationsSeen,
} from '../../../store/user_notifications/actions';
import Moment from 'react-moment';

export const NotificationDropdown: FC = () => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const [userNotifications, setUserNotifications] =
    useState<UserNotification[]>([]);
  const [userNotificationsCount, setUserNotificationsCount] = useState(0);

  const notificationsSelector = useSelector((state) => {
    return getUserNotificationsSelector(state);
  });

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [dispatch]);

  useEffect(() => {
    setUserNotifications(notificationsSelector);
  }, [notificationsSelector.length]);

  useEffect(() => {
    setUserNotificationsCount(userNotifications.filter(
        (userNotification) =>
          userNotification.status === 0).length);
  }, [userNotifications]);

  function handleDropdownClick() {
    setUserNotificationsCount(0);
    dispatch(setUserNotificationsSeen());
  }

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
        onClick={() => handleDropdownClick()}
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button" id="page-header-notifications-dropdown"
        >
          <i
            className={'bx bx-bell' +
              (userNotificationsCount ? ' bx-tada' : '')}
          />
          <span className="badge badge-danger badge-pill">
            {userNotificationsCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notifications</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{height: '230px'}}>
            {userNotifications && userNotifications.map(
                (userNotification, index) =>
                  <div
                    key={`notification_${index}`}
                    className="text-reset notification-item"
                  >
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span
                          className="avatar-title bg-primary rounded-circle
                          font-size-16"
                        >
                          <i className="bx bx-news"/>
                        </span>
                      </div>
                      {userNotification.notification &&
                        <div className="media-body">
                          <h6 className="mt-0 mb-1">
                            {userNotification.notification.subject}
                          </h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1"
                              dangerouslySetInnerHTML={
                                {__html: userNotification.notification.message}
                              }
                            />
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline"/>
                              <Moment
                                format="DD-MM-YYYY">
                                {userNotification.notification.created_at}
                              </Moment>
                            </p>
                          </div>
                        </div>}
                    </div>
                  </div>)}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
