// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';

import {
  CREATE_PRINTER,
  GET_PRINTER,
} from './actionTypes';
import {printerReceived, printerCreated} from './actions';

import {getPrinterFromBackend, createPrinterInBackend} from '../../api/printer';
import {apiError} from '../auth/login/actions';

/**
 * Retrieving Printer
 * @param {*} param0
 */
function* getPrinter({payload: printerSerial}) {
  try {
    const data = yield call(getPrinterFromBackend, printerSerial);
    yield put(printerReceived(data));
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Create Printer
 * @param {*} param0
 */
function* createPrinter({payload: printer}) {
  try {
    const data = yield call(createPrinterInBackend, printer);
    yield put(printerCreated(data));
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Watchers
 */
export function* watchGetPrinter() {
  yield takeEvery(GET_PRINTER, getPrinter);
  yield takeEvery(CREATE_PRINTER, createPrinter);
}

/**
 * Saga
 */
function* PrinterSaga() {
  yield all([
    fork(watchGetPrinter),
  ]);
}

export default PrinterSaga;
