import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';

const LatestClaimTableShort = (props) => {
  const claims = props.claims;

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap mb-0">
        <thead className="thead-light">
          <tr>
            <th>#FR</th>
            <th>Serial number</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {
            claims && claims.map((claim, key) =>
              <tr key={'_tr_' + key}>
                <td>{claim.fault_report_no}</td>
                <td>{claim.printer_serial_no}</td>
                <td>
                  <Link to={{
                    pathname: '/claim/' + claim.id,
                    state: {claim: claim.id},
                  }}>
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      className="btn-rounded waves-effect waves-light"
                    >
                        View Details
                    </Button>
                  </Link>
                </td>
              </tr>,
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default LatestClaimTableShort;
