// This is just for the Loading or Error Selector
export const GET_NOTIFICATION = 'GET_NOTIFICATION';

// Use these for Actions
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';

// This is just for the Loading or Error Selector
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';

// Use these for Actions
export const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_ERROR = 'CREATE_NOTIFICATION_ERROR';

// This is just for the Loading or Error Selector
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';

// Use these for Actions
export const EDIT_NOTIFICATION_REQUEST = 'EDIT_NOTIFICATION_REQUEST';
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS';
export const EDIT_NOTIFICATION_ERROR = 'EDIT_NOTIFICATION_ERROR';

// This is just for the Loading or Error Selector
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

// Use these for Actions
export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';

