// @flow
import {
  CREATE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
} from './actionTypes';

const initialState = {};

const Notification = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      return action.payload;
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        data: action.payload,
      };

    default:
      return state;
  }
};

export default Notification;
