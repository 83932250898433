/**
 * @description selector to get error state for array of actions.
 * @param {*} actions
 * @return {*}
 */
export const createErrorSelector: (actions: string[]) => (state: any) =>
  ApiError[] =
    (actions) =>
      (state) => {
        return (actions.map(
            (action) =>
                    (state && state.error && state.error[action]) ?
                      state.error[action] : [],
        ).find(
            (action) => action,
        ));
      };
