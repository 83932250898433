// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {
  GET_ENGINEER_REQUEST,
  CREATE_ENGINEER_REQUEST,
} from './actionTypes';
import {
  getEngineerFromBackend, createEngineerInBackend,
} from '../../api/engineer';
import {
  createEngineerError,
  createEngineerSuccess,
  getEngineerError,
  getEngineerSuccess,
} from './actions';

/**
 * Retrieving Customers
 * @param {*} param0
 */
function* getEngineer({payload: engineerId}) {
  try {
    const data = yield call(getEngineerFromBackend, engineerId);
    yield put(getEngineerSuccess(data));
  } catch (error) {
    yield put(getEngineerError(error));
  }
}

/**
 * Create Customer
 * @param {*} param0
 */
function* createEngineer({payload: engineer}) {
  try {
    const data = yield call(createEngineerInBackend, engineer);
    yield put(createEngineerSuccess(data));
  } catch (error) {
    yield put(createEngineerError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_ENGINEER_REQUEST, getEngineer);
  yield takeEvery(CREATE_ENGINEER_REQUEST, createEngineer);
}

/**
 * Saga
 */
function* EngineerSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default EngineerSaga;
