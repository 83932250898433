import React, {FC, useEffect, useState} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';

import {useDispatch, useSelector} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import './datatables.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getServices,
} from '../../store/actions';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {getServicesSelector} from '../../selectors/service';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_SERVICES} from '../../store/services/actionTypes';
import {ServiceList} from '../../components/Services/ServiceList';

const ServicesOverview: FC = () => {
  const services = useSelector(getServicesSelector);
  const dispatch = useDispatch();

  const authUser = getLoggedInUser();

  const servicesLoadingSelector = useSelector(
      createLoadingSelector([GET_SERVICES]),
  );
  const [servicesLoading, setServicesLoading] = useState(true);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  useEffect(() => {
    setServicesLoading(servicesLoadingSelector);
  }, [servicesLoadingSelector]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Services" breadcrumbItem="Overview"/>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {[
                    'manager', 'staff', 'engineer',
                  ].includes(authUser.user.role) &&
                    <div className="text-sm-right">
                      <Link to={{
                        pathname: '/add-service',
                      }}>
                        <Button
                          type="button"
                          color="yellow"
                          className="btn-rounded waves-effect waves-light mr-2">
                          <i className="mdi mdi-plus mr-1"/>
                            Add Maintenance Service
                        </Button>
                      </Link>
                    </div>
                  }
                  <ServiceList
                    isLoading={servicesLoading}
                    services={services}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicesOverview;
