// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {getClaimsFromBackend} from '../../api/claims';
import {getClaimsError, getClaimsSuccess} from './actions';
import {GET_CLAIMS_REQUEST} from './actionTypes';

/**
 * Retrieving Claims
 * @param {*} param0
 */
function* getClaims({payload: user}) {
  try {
    const data = yield call(getClaimsFromBackend);
    yield put(getClaimsSuccess(data));
  } catch (error) {
    yield put(getClaimsError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_CLAIMS_REQUEST, getClaims);
}

/**
 * Saga
 */
function* ClaimsSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default ClaimsSaga;
