import React, {FC} from 'react';
import {
  Card,
  CardBody,
} from 'reactstrap';
import {LoadingIndicator} from '../../LoadingIndicator';
import {Badge} from 'react-bootstrap';

interface EngineerCardProps {
    engineer?: FullEngineer,
    isLoading?: boolean
}

export const EngineerCard: FC<EngineerCardProps> = (
    {
      engineer,
      isLoading,
    }) => {
  return (
    <Card>
      <CardBody>
        {engineer && !isLoading &&
          <div>
            <h5>{engineer.firstname} {engineer.lastname}</h5>
            <p>{engineer.dealer.name}</p>
            <p><a href={'mailto:' + engineer.email}>{engineer.email}</a>
            </p>
            {engineer.street &&
              <p className="text-muted mb-1">
                {engineer.street} {engineer.street_no}
              </p>}
            {engineer.city &&
              <p className="text-muted mb-1">
                {engineer.zip_code} {engineer.city}
              </p>}
            {engineer.country &&
              <p className="text-muted mb-1">{engineer.country}</p>}
            {engineer.activation !== '1' ?
              <Badge
                pill
                variant='warning'
                className='badge-soft-warning'
              >
                Unconfirmed
              </Badge> :
              <Badge
                pill
                variant='success'
                className='badge-soft-success'
              >
                Confirmed
              </Badge>
            }
          </div>
        }
        {isLoading && <LoadingIndicator/>}
      </CardBody>
    </Card>
  );
};

