import React, {FC, useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './datatables.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {getClaims} from '../../store/actions';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {useDispatch, useSelector} from 'react-redux';
import {
  getAllGrantedClaimsSelector,
  getClaimsSelector,
  getGuaranteeGrantedClaimsSelector,
  getInProgressClaimsSelector,
  getInternalGrantedClaimsSelector,
  getNotGrantedClaimsSelector,
  getOpenClaimsSelector,
  getOtherPartClaimsSelector,
  getPrintHeadClaimsSelector,
} from '../../selectors/claim';
import {ClaimList} from '../../components/Claim/ClaimList';
import {Filter, FilterGroup} from '../../components/Filter/FilterGroup';
import {ROLE, RoleNameMap} from '../../components/Role/constants';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_CLAIMS} from '../../store/claims/actionTypes';

/* eslint-disable no-unused-vars */
enum CLAIM_FILTER {
    ALL,
    OPEN,
    IN_PROGRESS,
    GUARANTEE_GRANTED,
    INTERNAL_GRANTED,
    ALL_GRANTED,
    GUARANTEE_NOT_GRANTED,
    PRINT_HEAD,
    OTHER_PART
}

const ClaimFilterLabelMap = new Map<CLAIM_FILTER, string>([
  [CLAIM_FILTER.ALL, 'ALL'],
  [CLAIM_FILTER.OPEN, 'OPEN'],
  [CLAIM_FILTER.IN_PROGRESS, 'IN PROGRESS'],
  [CLAIM_FILTER.GUARANTEE_GRANTED, 'GRANTED'],
  [CLAIM_FILTER.INTERNAL_GRANTED, 'INTERNAL'],
  [CLAIM_FILTER.ALL_GRANTED, 'ALL GRANTED'],
  [CLAIM_FILTER.GUARANTEE_NOT_GRANTED, 'NOT GRANTED'],
  [CLAIM_FILTER.PRINT_HEAD, 'PRINT HEAD'],
  [CLAIM_FILTER.OTHER_PART, 'OTHER PART'],
]);

const ClaimsOverview: FC = () => {
  const allClaims = useSelector(getClaimsSelector);
  const openClaims = useSelector(getOpenClaimsSelector);
  const inProgressClaims = useSelector(getInProgressClaimsSelector);
  const guaranteeGrantedClaims = useSelector(getGuaranteeGrantedClaimsSelector);
  const internalGrantedClaims = useSelector(getInternalGrantedClaimsSelector);
  const allGrantedClaims = useSelector(getAllGrantedClaimsSelector);
  const notGrantedClaims = useSelector(getNotGrantedClaimsSelector);
  const printHeadClaims = useSelector(getPrintHeadClaimsSelector);
  const otherPartClaims = useSelector(getOtherPartClaimsSelector);
  const claimsLoadingSelector = useSelector(
      createLoadingSelector([GET_CLAIMS]),
  );

  const [claimsLoading, setClaimsLoading] = useState(true);

  const dispatch = useDispatch();

  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  const [claims, setClaims] = useState<Claim[]>(allClaims);
  const [activeFilter, setActiveFilter] = useState(CLAIM_FILTER.ALL);

  const brotherStaffFilters: Filter[] = [
    {
      active: activeFilter === CLAIM_FILTER.ALL,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.ALL) || '',
      method: () => setActiveFilter(CLAIM_FILTER.ALL),
      itemCount: allClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.OPEN,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.OPEN) || '',
      method: () => setActiveFilter(CLAIM_FILTER.OPEN),
      itemCount: openClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.IN_PROGRESS,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.IN_PROGRESS) || '',
      method: () => setActiveFilter(CLAIM_FILTER.IN_PROGRESS),
      itemCount: inProgressClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.GUARANTEE_GRANTED,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.GUARANTEE_GRANTED) || '',
      method: () => setActiveFilter(CLAIM_FILTER.GUARANTEE_GRANTED),
      itemCount: guaranteeGrantedClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.INTERNAL_GRANTED,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.INTERNAL_GRANTED) || '',
      method: () => setActiveFilter(CLAIM_FILTER.INTERNAL_GRANTED),
      itemCount: internalGrantedClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.ALL_GRANTED,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.ALL_GRANTED) || '',
      method: () => setActiveFilter(CLAIM_FILTER.ALL_GRANTED),
      itemCount: allGrantedClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.GUARANTEE_NOT_GRANTED,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.GUARANTEE_NOT_GRANTED) || '',
      method: () => setActiveFilter(CLAIM_FILTER.GUARANTEE_NOT_GRANTED),
      itemCount: notGrantedClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.PRINT_HEAD,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.PRINT_HEAD) || '',
      method: () => setActiveFilter(CLAIM_FILTER.PRINT_HEAD),
      itemCount: printHeadClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.OTHER_PART,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.OTHER_PART) || '',
      method: () => setActiveFilter(CLAIM_FILTER.OTHER_PART),
      itemCount: otherPartClaims.length,
    },
  ];

  const engineerFilters: Filter[] = [
    {
      active: activeFilter === CLAIM_FILTER.ALL,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.ALL) || '',
      method: () => setActiveFilter(CLAIM_FILTER.ALL),
      itemCount: allClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.OPEN,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.OPEN) || '',
      method: () => setActiveFilter(CLAIM_FILTER.OPEN),
      itemCount: openClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.IN_PROGRESS,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.IN_PROGRESS) || '',
      method: () => setActiveFilter(CLAIM_FILTER.IN_PROGRESS),
      itemCount: inProgressClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.ALL_GRANTED,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.GUARANTEE_GRANTED) || '',
      method: () => setActiveFilter(CLAIM_FILTER.ALL_GRANTED),
      itemCount: allGrantedClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.GUARANTEE_NOT_GRANTED,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.GUARANTEE_NOT_GRANTED) || '',
      method: () => setActiveFilter(CLAIM_FILTER.GUARANTEE_NOT_GRANTED),
      itemCount: notGrantedClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.PRINT_HEAD,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.PRINT_HEAD) || '',
      method: () => setActiveFilter(CLAIM_FILTER.PRINT_HEAD),
      itemCount: printHeadClaims.length,
    }, {
      active: activeFilter === CLAIM_FILTER.OTHER_PART,
      label: ClaimFilterLabelMap.get(CLAIM_FILTER.OTHER_PART) || '',
      method: () => setActiveFilter(CLAIM_FILTER.OTHER_PART),
      itemCount: otherPartClaims.length,
    },
  ];

  const activeFilters = [
    ROLE.BROTHER_STAFF,
    ROLE.BROTHER_MANAGER,
    ROLE.BROTHER_SALES,
  ]
      .includes(userRole) ? brotherStaffFilters :
        (
            userRole === ROLE.DEALER_ENGINEER ? engineerFilters : []
        );

  useEffect(() => {
    dispatch(getClaims());
  }, [dispatch]);

  useEffect(() => {
    switch (activeFilter) {
      case CLAIM_FILTER.ALL:
        setClaims(allClaims);
        break;
      case CLAIM_FILTER.OPEN:
        setClaims(openClaims);
        break;
      case CLAIM_FILTER.IN_PROGRESS:
        setClaims(inProgressClaims);
        break;
      case CLAIM_FILTER.GUARANTEE_GRANTED:
        setClaims(guaranteeGrantedClaims);
        break;
      case CLAIM_FILTER.INTERNAL_GRANTED:
        setClaims(internalGrantedClaims);
        break;
      case CLAIM_FILTER.ALL_GRANTED:
        setClaims(allGrantedClaims);
        break;
      case CLAIM_FILTER.GUARANTEE_NOT_GRANTED:
        setClaims(notGrantedClaims);
        break;
      case CLAIM_FILTER.PRINT_HEAD:
        setClaims(printHeadClaims);
        break;
      case CLAIM_FILTER.OTHER_PART:
        setClaims(otherPartClaims);
        break;
    }
  }, [
    activeFilter,
    allClaims,
    openClaims,
    inProgressClaims,
    guaranteeGrantedClaims,
    internalGrantedClaims,
    allGrantedClaims,
    notGrantedClaims,
    printHeadClaims,
    otherPartClaims,
  ],
  );

  useEffect(() => {
    setClaimsLoading(claimsLoadingSelector);
  }, [claimsLoadingSelector]);

  return (
    <div className="page-content">
      <Container fluid>

        <Breadcrumbs title="Claims" breadcrumbItem="Overview"/>

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {[
                  ROLE.BROTHER_STAFF,
                  ROLE.BROTHER_MANAGER,
                  ROLE.DEALER_ENGINEER,
                ].includes(userRole) &&
                  <>
                    <div className="text-sm-right">
                      <Link to={{
                        pathname: '/add-claim',
                      }}>
                        <Button
                          type="button"
                          color="yellow"
                          className="btn-rounded waves-effect waves-light mr-2">
                          <i className="mdi mdi-plus mr-1"/>
                            Add New Claim
                        </Button>
                      </Link>
                    </div>
                  </>
                }
                <Row>
                  <Col xl={11} lg={12}>
                    <FilterGroup
                      filters={activeFilters}
                      isLoading={claimsLoading}
                    />
                  </Col>
                </Row>
                <ClaimList
                  claims={claims}
                  isLoading={claimsLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClaimsOverview;
