import React from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import {MDBDataTable} from 'mdbreact';
import ClaimStatusOld from './claim-status_old';

const ClaimsTableOld = (props) => {
  const claims = props.claims;

  claims && claims.forEach((element) => {
    element.date_of_visit = <Moment format="DD-MM-YYYY">
      {element.date_of_visit}
    </Moment>;
    element.status_label = <ClaimStatusOld claim={element}/>;

    element.actions = <div><Link to={{
      pathname: '/claim/' + element.id,
      state: {printerId: element.id},
    }} className="mr-3 text-primary">
      <button
        className="btn btn-primary btn-sm waves-effect waves-light btn
        btn-primary"
      >
        View Details
      </button>
    </Link></div>;
  });

  // eslint-disable-next-line camelcase
  const data_claims = {
    columns: [
      {
        label: '#FR',
        field: 'fault_report_no',
        width: 84,
      },
      {
        label: 'Date',
        field: 'date_of_visit',
        width: 104,
      },
      {
        label: 'Claim Status',
        field: 'status_label',
        width: 109,
      },
      {
        label: 'Actions',
        field: 'actions',
        width: 126,
      },
    ],
    rows: claims,
  };

  /* eslint-disable camelcase */
  return (
    <React.Fragment>
      <h4 className="card-title">Claims Overview</h4>
      <MDBDataTable
        fixed
        striped
        noBottomColumns
        info={false}
        displayEntries={false}
        barReverse
        responsive
        bordered
        data={data_claims}
        className="mt-5"
      />
    </React.Fragment>
  );
};

export default ClaimsTableOld;
