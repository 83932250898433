import {combineReducers} from 'redux';
import Account from './auth/register/reducer';
import Claims from './claims/reducer';
import Claim from './claim/reducer';
import Customers from './customers/reducer';
import Customer from './customer/reducer';
import Dealers from './dealers/reducer';
import Dealer from './dealer/reducer';
import Dispatches from './dispatches/reducer';
import Engineers from './engineers/reducer';
import Engineer from './engineer/reducer';
import Error from './error/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Layout from './layout/reducer';
import Loading from './loading/reducer';
import Login from './auth/login/reducer';
import Machines from './machines/reducer';
import Machine from './machine/reducer';
import Notifications from './notifications/reducer';
import Notification from './notification/reducer';
import Overtakes from './overtakes/reducer';
import Profile from './auth/profile/reducer';
import Printers from './printers/reducer';
import Printer from './printer/reducer';
import ResetPassword from './auth/resetpwd/reducer';
import Services from './services/reducer';
import UserNotifications from './user_notifications/reducer';

const rootReducer = combineReducers({
  Account,
  Claims,
  Claim,
  Customers,
  Customer,
  Dealers,
  Dealer,
  Dispatches,
  Engineers,
  Engineer,
  Error,
  ForgetPassword,
  Layout,
  Loading,
  Login,
  Machines,
  Machine,
  Notifications,
  Notification,
  Overtakes,
  Printers,
  Printer,
  Profile,
  ResetPassword,
  Services,
  UserNotifications,
});

export default rootReducer;
