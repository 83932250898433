import React from 'react';
import Moment from 'react-moment';
import {Card, CardBody, CardTitle, Media} from 'reactstrap';

const MachineHistory = (props) => {
  const machine = props.machine;

  const events = [];

  machine?.history?.map((historyItem) => {
    let text;
    switch (historyItem.action) {
      case 'printer_installed':
        text = <React.Fragment>Machine has been installed to printer {historyItem.printer_serial_no}</React.Fragment>;
        break;
      case 'printer_removed':
        text = <React.Fragment>Machine has been removed from printer {historyItem.printer_serial_no}</React.Fragment>;
        break;
      default:
        text = '';
    }

    events.push({
      'date': historyItem.created_at,
      'text': text,
    });
  });

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">
            Last Activities
          </CardTitle>
          <ul className="verti-timeline list-unstyled">
            {events.sort((a, b) => {
              return new Date(a.date).getTime() -
                new Date(b.date).getTime();
            }).reverse().map(
                (event, index) =>
                  <li className="event-list" key={'event-' + index}>
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18"/>
                    </div>
                    <Media>
                      <div className="mr-3">
                        <strong className="font-size-14">
                          <Moment format="DD-MM-YYYY">{event.date}</Moment>
                          <i className="bx bx-right-arrow-alt font-size-16
                          text-primary align-middle ml-2"/>
                        </strong>
                      </div>
                      <Media body>
                        <div>
                          {event.text}
                        </div>
                      </Media>
                    </Media>
                  </li>,
            )}
          </ul>
        </CardBody>
      </Card>
    </>
  );
};

export default MachineHistory;
