/**
 * @description selector to get all machines.
 * @param {*} state
 * @return {*}
 */
export const getMachinesSelector: (state: any) => Machine[] =
  (state) => ((state.Machines && state.Machines.data) ?
    state.Machines.data : []);

export const getMachineByIdSelector = (state: any, id: number) =>
  (state.Machine && state.Machine.data && state.Machine.data.id === id) ?
    state.Machine.data : undefined;
