import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getCustomers} from '../../store/customers/actions';
import AvSelect from '../Common/AvSelect';
import {AvFeedback, AvGroup} from 'availity-reactstrap-validation';

const ListCustomers = (props) => {
  const [customers, setCustomers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  useEffect(() => {
    props.getCustomers();
  }, []);

  useEffect(() => {
    if (props.data) {
      setCustomers(props.data.map((customer) => ({
        ...customer,
        value: customer.id,
        label: customer.name,
      })));
    }
  }, [props.data]);

  function handleSelectCustomer(customer) {
    setSelectedCustomer(customer);
    props.onChange(customer);
  }

  return (
    <AvGroup>
      <AvSelect
        name="customer"
        options={customers}
        helpMessage="Select a customer."
        validate={{required: {value: props.required}}}
        onChange={(customer) => {
          handleSelectCustomer(customer);
        }}
        raw={true}
      />
      <AvFeedback>Please choose a valid option.</AvFeedback>
    </AvGroup>
  );
};

const mapStatetoProps = (state) => {
  const {data} = state.Customers;
  return {data};
};

export default connect(mapStatetoProps, {
  getCustomers,
})(ListCustomers);
