import React, {FC, useMemo} from 'react';
import MUIDataTable, {MUIDataTableOptions} from 'mui-datatables';
import {LoadingIndicator} from '../../LoadingIndicator';
import {MuiThemeProvider, Typography} from '@material-ui/core';
import {MuiToolbarThemeWithHover} from '../../../helpers/themes/MuiThemes';
import {useHistory} from 'react-router-dom';

interface CustomerListProps {
    isLoading: boolean,
    customers: OverviewCustomer[]
}

export const CustomerList: FC<CustomerListProps> = (
    {
      isLoading,
      customers,
    }) => {
  const history = useHistory();

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Dealer',
      name: 'dealer_name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Country',
      name: 'country',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Total Printers',
      name: 'printers_total',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const dataList = useMemo(
      () => ({
        columns: [...columns],
        rows: [...customers.map(
            (customer) => ({
              id: customer.id,
              name: customer.name,
              country: customer.country,
              dealer_name: customer?.dealer?.name,
              printers_total: customer.printers_total || 0,
            }),
        )],
      }), [customers, columns],
  );

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    onRowClick: (rowData) => history.push('/customer/' + rowData[0]),
    textLabels: {
      body: {
        noMatch: isLoading ?
          <LoadingIndicator fontSize={24}/> :
          'Sorry, no matching records found',
      },
    },
  };

  return (
    <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
      <MUIDataTable
        title={!isLoading ? 'Customers' :
          <Typography variant="h6">
              Customers <LoadingIndicator />
          </Typography>}
        data={dataList.rows}
        columns={dataList.columns}
        options={options}
      />
    </MuiThemeProvider>
  );
};
