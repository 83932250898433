import authHeader from '../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

const getEngineerFromBackend = (engineerId) => {
  const url = process.env.REACT_APP_APIURL + 'engineer/' + engineerId;
  const authToken = authHeader();

  return axios.get(url, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((err) => {
    throw err[1];
  });
};

const createEngineerInBackend = (engineer) => {
  const url = process.env.REACT_APP_APIURL + 'engineer';
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(engineer));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
};

export {getEngineerFromBackend, createEngineerInBackend};
