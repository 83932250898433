// This is just for the Loading or Error Selector
export const GET_CUSTOMER = 'GET_CUSTOMER';

// Use these for Actions
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

// This is just for the Loading or Error Selector
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';

// Use these for Actions
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

// This is just for the Loading or Error Selector
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';

// Use these for Actions
export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_ERROR = 'EDIT_CUSTOMER_ERROR';
