/* eslint-disable no-unused-vars */
export enum ROLE {
    NONE,
    BROTHER_MANAGER,
    BROTHER_STAFF,
    BROTHER_SALES,
    DEALER_SALES,
    DEALER_ENGINEER,
    DISPATHCHER
}

export const RoleNameMap = new Map<string, ROLE>([
  ['none', ROLE.NONE],
  ['manager', ROLE.BROTHER_MANAGER],
  ['staff', ROLE.BROTHER_STAFF],
  ['sales', ROLE.BROTHER_SALES],
  ['dealer-sales', ROLE.DEALER_SALES], // @TODO have to check this
  ['engineer', ROLE.DEALER_ENGINEER],
  ['dispatcher', ROLE.DISPATHCHER],
]);
/* eslint-enable no-unused-vars */
