import {
  GET_CLAIM,
  CREATE_CLAIM,
  CLAIM_RECEIVED, CLAIM_CREATED,
} from './actionTypes';

export const getClaim = (claimId) => ({
  type: GET_CLAIM,
  payload: claimId,
});

export const claimReceived = (data) => {
  return {
    type: CLAIM_RECEIVED,
    payload: data,
  };
};

export const createClaim = (claim) => ({
  type: CREATE_CLAIM,
  payload: claim,
});

export const claimCreated = (claim) => {
  return {
    type: CLAIM_CREATED,
    payload: claim,
  };
};
