// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';
import {
  GET_MACHINE_REQUEST,
  CREATE_MACHINE_REQUEST,
  EDIT_MACHINE_REQUEST,
} from './actionTypes';
import {
  createMachineSuccess,
  createMachineError,
  getMachineSuccess,
  getMachineError,
  editMachineSuccess,
  editMachineError,
} from './actions';
import {
  getMachineFromBackend,
  createMachineInBackend,
  editMachineInBackend,
} from '../../api/machine';

/**
 * Retrieving machines
 * @param {*} param0
 */
function* getMachine({payload: machineId}) {
  try {
    const data = yield call(getMachineFromBackend, machineId);
    yield put(getMachineSuccess(data));
  } catch (error) {
    yield put(getMachineError(error));
  }
}

/**
 * Create machine
 * @param {*} param0
 */
function* createMachine({payload: machine}) {
  try {
    const data = yield call(createMachineInBackend, machine);
    yield put(createMachineSuccess(data));
  } catch (error) {
    yield put(createMachineError(error));
  }
}

/**
 * Edit Machine
 * @param {*} param0
 */
function* editMachine({payload: machine}) {
  try {
    const data = yield call(editMachineInBackend, machine);
    yield put(editMachineSuccess(data));
  } catch (error) {
    yield put(editMachineError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_MACHINE_REQUEST, getMachine);
  yield takeEvery(CREATE_MACHINE_REQUEST, createMachine);
  yield takeEvery(EDIT_MACHINE_REQUEST, editMachine);
}

/**
 * Saga
 */
function* machineSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default machineSaga;
