// @flow
import {
  GET_ENGINEER_SUCCESS,
  CREATE_ENGINEER_SUCCESS,
} from './actionTypes';

const initialState = {

};

const Engineer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENGINEER_SUCCESS:
      return action.payload;
    case CREATE_ENGINEER_SUCCESS:
      return {
        data: action.payload,
      };

    default:
      return state;
  }
};

export default Engineer;
