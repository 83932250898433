import React from 'react';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import ClaimStatus from '../../components/Claim/ClaimStatus';

const LatestClaimTable = (props) => {
  const claims = props.claims;

  const authUser = getLoggedInUser();

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap mb-0">
        <thead className="thead-light">
          <tr>
            <th>#FR</th>
            <th>Serial number</th>
            <th>Customer</th>
            <th>Claim</th>
            <th>Input</th>
            {(authUser.user.role === 'manager' ||
                authUser.user.role === 'staff') &&
                    <th>Location</th>}
            <th>Claim Status</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {
            claims && claims.map((claim, key) =>
              <tr key={'_tr_' + key}>
                <td>{claim.fault_report_no}</td>
                <td>{claim.printer_serial_no}</td>
                <td>
                  {claim.customer && claim.customer.name}
                </td>
                <td>
                  <Moment format="DD-MM-YYYY">{claim.date_of_visit}</Moment>
                </td>
                <td>
                  <Moment format="DD-MM-YYYY">{claim.created_at}</Moment>
                </td>
                {(authUser.user.role === 'manager' ||
                    authUser.user.role === 'staff') &&
                            <td>{claim.storage_location}</td>}
                <td>
                  <ClaimStatus
                    status={claim.status}
                    final_check_status={claim.final_check_status}
                    internal_check_status={claim.internal_check_status}
                  />
                </td>
                <td>
                  <Link to={{
                    pathname: '/claim/' + claim.id,
                    state: {claim: claim.id},
                  }}>
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      className="btn-rounded waves-effect waves-light"
                    >
                      View Details
                    </Button>
                  </Link>
                </td>
              </tr>,
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default LatestClaimTable;
