import {createTheme} from '@material-ui/core/styles';

export const MuiToolbarThemeWithHover = createTheme({
  overrides: {
    MuiToolbar: {
      gutters: {
        padding: '16px !important',
      },
    },
    MUIDataTableBodyRow: {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
},
);

export const MuiToolbarTheme = createTheme({
  overrides: {
    MuiToolbar: {
      gutters: {
        padding: '16px !important',
      },
    },
  },
},
);
