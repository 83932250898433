/**
 * @description selector to get all dealers.
 * @param {*} state
 * @return {*}
 */
export const getUserNotificationsSelector: (state: any) => UserNotification[] =
    (state) => ((state.UserNotifications && state.UserNotifications.data) ?
      state.UserNotifications.data : []);

export const getUserNotificationByIdSelector = (state: any, id: number) =>
  (state?.UserNotification?.data?.id === id) ?
    state.UserNotification.data : undefined;
