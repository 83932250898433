import React, {useEffect, useState} from 'react';
import {Button, Row, Col, Modal} from 'reactstrap';
import {Link} from 'react-router-dom';
import {MDBDataTable} from 'mdbreact';
import Moment from 'react-moment';
import ClaimStatus from '../Claim/ClaimStatus';

const ModalExistingCustomers = (props) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (props.customers && props.customers.length > 0) {
      setModal(true);
    }
  }, [props.customers]);

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function toggleModal() {
    setModal(!modal);
    removeBodyCss();
  }

  props.customers && props.customers.forEach((element) => {
    element.date_of_visit =
      <Moment format="DD-MM-YYYY">
        {element.date_of_visit}
      </Moment>;
    element.status_label =
      <ClaimStatus
        status={element.status}
        final_check_status={element.final_check_status}
        internal_check_status={element.internal_check_status}
      />;

    // @TODO delete this?
    // element.actions = <div>
    //     <Link
    //         to={{
    //             pathname: "/add-printer",
    //         }} className="mr-3 text-primary">
    //         <button
    //           className="btn btn-primary btn-sm waves-effect waves-light btn
    //           btn-primary">
    //           Add Printer
    //         </button>
    //     </Link>
    // </div>;
  });

  /* eslint-disable camelcase */
  const data_customers = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        width: 84,
      },
      {
        label: 'City',
        field: 'city',
        width: 104,
      },
      {
        label: 'Country',
        field: 'country',
        width: 109,
      },
      // {
      //     label: "Actions",
      //     field: "actions",
      //     width: 126
      // }
    ],
    rows: props.customers,
  };

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <button
            type="button"
            onClick={() => {
              setModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Row>
            <Col md={1}>
              <div className="avatar-sm">
                <span
                  className="avatar-title rounded-circle bg-primary text-white">
                  <i className="bx bx-refresh bx-sm rotateInUpLeft"/>
                </span>
              </div>
            </Col>
            <Col md={11}>
              <div className="mb-2">
                <h5 className="modal-title mt-0">We found similar Customer</h5>
                <p>Is this one of the customers you are looking for?</p>
              </div>
              <MDBDataTable
                fixed
                striped
                noBottomColumns
                info={false}
                displayEntries={false}
                barReverse
                responsive
                bordered
                data={data_customers}
                className="mt-5"
              />
              <div className="d-flex">
                <Button
                  onClick={() => {
                    setModal(false);
                  }}
                  className="btn btn-yellow mr-2">
                                    Add new Customer </Button>
                <Link to="/add-printer" className="btn btn-yellow mr-2">
                                    Add new Printer
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};
/* eslint-enable camelcase */

export default ModalExistingCustomers;
