import axios from 'axios';
import authHeader from '../helpers/jwt-token-access/auth-token-header';

const getDispatchesFromBackend = () => {
  const url = process.env.REACT_APP_APIURL + 'dispatches';
  const authToken = authHeader();

  return axios.get(url, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((err) => {
    throw err[1];
  });
};

const confirmDispatchFromBackend = (dispatch) => {
  const url = process.env.REACT_APP_APIURL + 'dispatch/confirm';
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(dispatch));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
};

// const confirmDispatchFromBackend = async (dispatch) => {
//     try {
//         const authToken = authHeader();
//         const formData = new FormData();
//         formData.append('data', JSON.stringify(dispatch));
//
//         const response = await fetch(process.env.REACT_APP_APIURL +
//         'dispatch/confirm', {
//             method: 'post',
//             headers: {
//                 'Accept': 'application/json, text/plain, */*',
//                 'Authorization': authToken.Authorization,
//             },
//             body: formData
//         });
//         return await response.json();
//     } catch (e) {
//         console.log(e);
//     }
// }

export {getDispatchesFromBackend, confirmDispatchFromBackend};
