// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';

import {
  GET_ENGINEERS_REQUEST,
} from './actionTypes';
import {
  getEngineersError,
  getEngineersSuccess,
} from './actions';

import {getEngineersFromBackend} from '../../api/engineers';

/**
 * Retrieving Claims
 * @param {*} param0
 */
function* getEngineers() {
  try {
    const data = yield call(getEngineersFromBackend);
    yield put(getEngineersSuccess(data));
  } catch (error) {
    yield put(getEngineersError(error));
  }
}

/**
 * Watchers
 */
export function* watchActions() {
  yield takeEvery(GET_ENGINEERS_REQUEST, getEngineers);
}

/**
 * Saga
 */
function* EngineersSaga() {
  yield all([
    fork(watchActions),
  ]);
}

export default EngineersSaga;
