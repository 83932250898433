/**
 * @description selector to get loading state for array of actions.
 * @param {*} actions
 * @return {*}
 */
export const createLoadingSelector: (actions: string[]) => (state: any) =>
  boolean =
    (actions) =>
      (state) => {
        return (actions.some(
            (action) =>
              state.Loading[action],
        ));
      };
