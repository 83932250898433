import React from 'react';
import {Card, CardBody, CardTitle, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';

const QuickNavigation = (props) => {
  const authUser = getLoggedInUser();

  return (
        ['manager', 'staff', 'engineer'].includes(authUser.user.role) ?
        <>
          <Card>
            <CardBody>
              <CardTitle className="text-yellow mb-4">
                        Quick navigation
              </CardTitle>
              <Row>
                <div className="col-lg-6">
                  <Link to="/add-printer">
                    <div className="text-white-50 card bg-yellow card-quicknav">
                      <div className="card-body">
                        <div className="mb-4 text-primary card-title">
                                            Add a new<br/> Printer
                        </div>
                        <i className="mdi mdi-arrow-right text-primary"/>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6">
                  <Link to="/add-claim">
                    <div className="text-white-50 card bg-yellow card-quicknav">
                      <div className="card-body">
                        <div className="mb-4 text-primary card-title">
                                            Add a new<br/> Claim
                        </div>
                        <i className="mdi mdi-arrow-right text-primary"/>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6">
                  <Link to="/add-customer">
                    <div className="text-white-50 card bg-yellow card-quicknav">
                      <div className="card-body">
                        <div className="mb-4 text-primary card-title">
                                            Add a new<br/> Customer
                        </div>
                        <i className="mdi mdi-arrow-right text-primary"/>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <div className="col-lg-6">*/}
                {/*  <ModalPrintheadExchange/>*/}
                {/* </div>*/}
                {/* <div className="col-lg-6">*/}
                {/*  <div*/}
                {/*    className="text-white-50 card bg-yellow*/}
                {/*      card-quicknav"*/}
                {/*  >*/}
                {/*    <div className="card-body">*/}
                {/*      <div className="mb-4 text-primary card-title">*/}
                {/*        <Link to="/overtake">*/}
                {/*                    Overtake a*/}
                {/*          <br/>*/}
                {/*                    Printer or a Customer*/}
                {/*        </Link>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/* </div>*/}
              </Row>
            </CardBody>
          </Card>
        </> : <></>
  );
};

export default QuickNavigation;
