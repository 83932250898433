import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './datatables.scss';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  getNotifications,
} from '../../store/actions';
import {Link} from 'react-router-dom';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {ROLE, RoleNameMap} from '../../components/Role/constants';
import {createLoadingSelector} from '../../selectors/loading';
import {GET_NOTIFICATIONS} from '../../store/notifications/actionTypes';
import {NotificationList} from '../../components/Notification/NotificationList';
import {getNotificationsSelector} from '../../selectors/notification';

const NotificationsOverview: FC = () => {
  const allNotifications = useSelector(getNotificationsSelector);
  const dispatch = useDispatch();

  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  const [notifications, setNotifications] = useState<OverviewNotification[]>(
      allNotifications,
  );
  const notificationsLoadingSelector = useSelector(
      createLoadingSelector([GET_NOTIFICATIONS]),
  );

  const [notificationsLoading, setNotificationsLoading] = useState(true);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  // Could be done in the other useEffect, but when Filters are used
  // separate are needed
  useEffect(() => {
    setNotifications(allNotifications);
  }, [allNotifications]);

  useEffect(() => {
    setNotificationsLoading(notificationsLoadingSelector);
  }, [notificationsLoadingSelector]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Notifications" breadcrumbItem="Overview"/>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {[
                  ROLE.BROTHER_MANAGER,
                  ROLE.BROTHER_STAFF,
                  ROLE.DEALER_ENGINEER,
                ].includes(userRole) &&
                  <div className="text-sm-right">
                    <Link to={{
                      pathname: '/add-notification',
                    }}>
                      <Button
                        type="button"
                        color="yellow"
                        className="btn-rounded waves-effect waves-light
                        mb-2 mr-2"
                      >
                        <i className="mdi mdi-plus mr-1"/>
                          Add New Notification
                      </Button>
                    </Link>
                  </div>
                }
                <NotificationList
                  notifications={notifications}
                  isLoading={notificationsLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationsOverview;
