import authHeader from '../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

const renewUserToken = () => {
  const url = process.env.REACT_APP_APIURL + 'auth/refresh';
  const authToken = authHeader();

  return axios.post(url, null, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((err) => {
    throw err[1];
  });
};

export {renewUserToken};
