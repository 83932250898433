// @flow
import {
  CLAIM_RECEIVED,
  CLAIM_CREATED,
} from './actionTypes';

const initialState = {

};

const Claim = (state = initialState, action) => {
  switch (action.type) {
    case CLAIM_RECEIVED:
      return action.payload;
    case CLAIM_CREATED:
      return {
        data: action.payload,
      };

    default:
      return state;
  }
};

export default Claim;
