import React, {FC} from 'react';
import {CLAIM_INTERNAL_CHECK_STATUS} from '../constants';
import {Badge} from 'react-bootstrap';

/* eslint-disable camelcase */
interface ClaimInternalStatusProps {
    internal_check_status: CLAIM_INTERNAL_CHECK_STATUS,
}

const ClaimInternalStatus: FC<ClaimInternalStatusProps> = (
    {
      internal_check_status,
    }) => {
  return (
    <>
      {internal_check_status === CLAIM_INTERNAL_CHECK_STATUS.GRANTED ?
        <Badge pill variant={'success'} className='badge-soft-success'>
          Guarantee granted
        </Badge> : ''
      }
      {internal_check_status === CLAIM_INTERNAL_CHECK_STATUS.INTERNAL_GRANTED ?
        <Badge pill variant={'warning'} className='badge-soft-warning'>
          Internal granted
        </Badge> : ''
      }
      {internal_check_status === CLAIM_INTERNAL_CHECK_STATUS.NOT_GRANTED ?
        <Badge pill variant={'danger'} className='badge-soft-danger'>
          Guarantee not granted
        </Badge> : ''
      }
    </>
  );
};
/* eslint-enable camelcase */

export default ClaimInternalStatus;
