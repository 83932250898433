import React, {FC, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import MUIDataTable, {MUIDataTableOptions} from 'mui-datatables';
import {LoadingIndicator} from '../../LoadingIndicator';
import {MuiToolbarThemeWithHover} from '../../../helpers/themes/MuiThemes';
import {MuiThemeProvider, Typography} from '@material-ui/core';

interface DealerListProps {
    isLoading: boolean,
    dealers: OverviewDealer[]
}

export const DealerList: FC<DealerListProps> = ({
  isLoading,
  dealers,
}) => {
  const history = useHistory();

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Country',
      name: 'country',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Total Printers',
      name: 'printers_total',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'GTX',
      name: 'printers_gtx',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'GTXpro',
      name: 'printers_gtxpro',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'GTXpro Bulk',
      name: 'printers_gtxprobulk',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'GT-3',
      name: 'printers_gt3',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const dataList = useMemo(
      () => ({
        columns: [...columns],
        rows: [...dealers.map(
            (dealer) => ({
              id: dealer.id || '',
              name: dealer.name || '',
              country: dealer.country || '',
              printers_gt3: dealer.printers_gt3 || 0,
              printers_gtx: dealer.printers_gtx || 0,
              printers_gtxpro: dealer.printers_gtxpro || 0,
              printers_gtxprobulk: dealer.printers_gtxprobulk || 0,
              printers_total: dealer.printers_total || 0,
            }),
        )],
      }), [dealers, columns],
  );

  const options: MUIDataTableOptions = {
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    rowsPerPage: 50,
    rowsPerPageOptions: [],
    selectableRows: 'none',
    onRowClick: (rowData) => history.push('/dealer/' + rowData[0]),
    textLabels: {
      body: {
        noMatch: isLoading ?
          <LoadingIndicator fontSize={24}/> :
          'Sorry, no matching records found',
      },
    },
  };

  return (
    <MuiThemeProvider theme={MuiToolbarThemeWithHover}>
      <MUIDataTable
        title={!isLoading ?
          'Dealers' :
          <Typography variant="h6">
              Dealers <LoadingIndicator />
          </Typography>}
        data={dataList.rows}
        columns={dataList.columns}
        options={options}
      />
    </MuiThemeProvider>
  );
};
