// @flow
import {all, call, fork, takeEvery, put} from 'redux-saga/effects';

import {
  GET_CLAIM,
  CREATE_CLAIM,
} from './actionTypes';
import {claimReceived, claimCreated} from './actions';

import {getClaimFromBackend, createClaimInBackend} from '../../api/claim';
import {apiError} from '../auth/login/actions';

/**
 * Retrieving Printer
 * @param {*} param0
 */
function* getClaim({payload: claimId}) {
  try {
    const data = yield call(getClaimFromBackend, claimId);
    yield put(claimReceived(data));
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Create Printer
 * @param {*} param0
 */
function* createClaim({payload: claim}) {
  try {
    const data = yield call(createClaimInBackend, claim);
    yield put(claimCreated(data));
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Watchers
 */
export function* watchGetClaim() {
  yield takeEvery(GET_CLAIM, getClaim);
  yield takeEvery(CREATE_CLAIM, createClaim);
}

/**
 * Saga
 */
function* ClaimSaga() {
  yield all([
    fork(watchGetClaim),
  ]);
}

export default ClaimSaga;
