// This is just for the Loading or Error Selector
export const GET_DEALER = 'GET_DEALER';

// Use these for Actions
export const GET_DEALER_REQUEST = 'GET_DEALER_REQUEST';
export const GET_DEALER_SUCCESS = 'GET_DEALER_SUCCESS';
export const GET_DEALER_ERROR = 'GET_DEALER_ERROR';

// This is just for the Loading or Error Selector
export const CREATE_DEALER = 'CREATE_DEALER';

// Use these for Actions
export const CREATE_DEALER_REQUEST = 'CREATE_DEALER_REQUEST';
export const CREATE_DEALER_SUCCESS = 'CREATE_DEALER_SUCCESS';
export const CREATE_DEALER_ERROR = 'CREATE_DEALER_ERROR';

// This is just for the Loading or Error Selector
export const EDIT_DEALER = 'EDIT_DEALER';

// Use these for Actions
export const EDIT_DEALER_REQUEST = 'EDIT_DEALER_REQUEST';
export const EDIT_DEALER_SUCCESS = 'EDIT_DEALER_SUCCESS';
export const EDIT_DEALER_ERROR = 'EDIT_DEALER_ERROR';

