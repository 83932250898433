import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {MUIDataTableColumn} from 'mui-datatables';

export interface TableAction {
    type: 'Link'|'Modal', // @TODO define more types if needed
    value: any, // @TODO define this more specific
}

export interface DataList {
    columns: MUIDataTableColumn[],
    rows: Array<object | number[] | string[]>, // @TODO define this better
}

interface TableActionsCustomRenderProps {
    actions: TableAction[],
}

const tableActionCustomRender: FC<TableActionsCustomRenderProps> =
  ({actions}) => {
    const actionObjects = actions.map((action) => {
      let currentAction = action.value;
      switch (action.type) {
      // @TODO add a case per TableAction type
        case 'Link':
          currentAction = (
            <Link to={action.value} className="mr-3 text-primary">
              <button
                className="btn btn-primary btn-sm waves-effect waves-light
              btn btn-primary"
              >
                View Details
              </button>
            </Link>
          );
          break;
      }
      return currentAction;
    });
    return (
        actions.length === 1 ?
            <div>
              {actionObjects[0]}
            </div> : <></> // @TODO if there are more than one action
    );
  };

export const actionColumn = {
  label: 'Actions',
  name: 'actions',
  options: {
    filter: false,
    sort: false,
    download: false,
    customBodyRender: (actions) => tableActionCustomRender({actions}),
  },
};
