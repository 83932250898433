import React, {useState} from 'react';
import {Button, Row, Col, Modal} from 'reactstrap';
import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from 'availity-reactstrap-validation';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useHistory} from 'react-router-dom';
import axios from 'axios';

const reasonList = [
  {label: 'Good will', value: 1},
  {label: '3 months', value: 2},
  {label: 'Other', value: 3},
];

const problemList = [
  {
    label: 'Mech:Maintenance unit broken',
    value: 'Mech:Maintenance unit broken',
  },
  {label: 'Mech:Cartridge broken', value: 'Mech:Cartridge broken'},
  {label: 'Mech:Ink supply area broken', value: 'Mech:Ink supply area broken'},
  {label: 'Mech:Tube broken', value: 'Mech:Tube broken'},
  {label: 'Mech:Platen broken', value: 'Mech:Platen broken'},
  {label: 'Mech:Cover broken', value: 'Mech:Cover broken'},
  {label: 'Mech:Cariage broken', value: 'Mech:Cariage broken'},
  {label: 'Mech:Motor broken', value: 'Mech:Motor broken'},
  {label: 'Mech:Other', value: 'Mech:Other'},
  {label: 'Head:Missing', value: 'Head:Missing'},
  {label: 'Head:Angled Firing', value: 'Head:Angled Firing'},
  {label: 'Head:Both', value: 'Head:Both'},
  {label: 'Head:CLP peeling off', value: 'Head:CLP peeling off'},
  {
    label: 'Head:Nozzle plate peeling off',
    value: 'Head:Nozzle plate peeling off',
  },
  {label: 'Head:Temp, voltage abnormal', value: 'Head:Temp, voltage abnormal'},
  {label: 'Head:Other', value: 'Head:Other'},
  {label: 'App/Firm:Other', value: 'App/Firm:Other'},
  {label: 'Ink:Ink pouch burst', value: 'Ink:Ink pouch burst'},
  {label: 'Ink:IC chip missing', value: 'Ink:IC chip missing'},
  {label: 'Sub:Main PCB broken', value: 'Sub:Main PCB broken'},
  {label: 'Sub:Carriage PCB broken', value: 'Sub:Carriage PCB broken'},
  {label: 'Sub:Ink Switch PCB broken', value: 'Sub:Ink Switch PCB broken'},
  {label: 'Sub:Other', value: 'Sub:Other'},
  {label: 'Other:Other', value: 'Other:Other'},
];

const ModalInternalCheck = (props) => {
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonChoice, setReasonChoice] = useState('');

  const history = useHistory();

  const claim = props.claim;

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function toggleModal() {
    setModal(!modal);
    removeBodyCss();
  }

  function handleValidSubmit(event, values) {
    let reason = '';
    if (values.reason_choice === '3' && values.other_reason) {
      reason = values.other_reason;
    } else if (values.reason_choice !== '') {
      reason = reasonList.find((reason) => {
        if (reason.value === values.reason_choice) {
          return reason.label;
        }
        return '';
      });
    }

    const internalCheck = {
      claim_id: claim.id,
      status: status,
      reply_choice: 0,
      standard_reply: values.standard_reply ? values.standard_reply.value : '',
      individual_reply: values.individual_reply ? values.individual_reply : '',
      reason_choice: values.reason_choice ? values.reason_choice : '',
      reason: reason ? reason : '',
      problem_type: values.problem_type ? values.problem_type : '',
    };
    setIsLoading(true);
    sendInternalCheck(internalCheck);
  }

  function handleChange(e) {
    if (e.target != null) {
      const {value} = e.target;
      setStatus(value);
    }
  };

  function handleChangeReasonChoice(reasonChoice) {
    setReasonChoice(reasonChoice);
  };

  function sendInternalCheck(internalCheck) {
    const url = process.env.REACT_APP_APIURL + 'claim/internalcheck';
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(internalCheck));

    return axios.post(url, formData, {headers: authToken}).then((response) => {
      setIsLoading(false);
      setSuccessMsg(true);
    }).catch((error) => {
      setIsLoading(false);
      setErrorMsg(true);
    });
  }

  return (
    <React.Fragment>
      <button className="mr-1 waves-effect waves-light btn btn-dark"
        type="button"
        onClick={() => {
          toggleModal();
        }}
        data-toggle="modal"
        data-target=".bs-example-modal-center"
      >
                Internal Check
      </button>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        onClosed={() => {
          setStatus('');
          setReasonChoice('');
        }}
        centered={true}
      >
        <div className="modal-body">
          <button
            type="button"
            onClick={() => {
              setModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Row>
            <Col md={1}>
              <div className="avatar-sm">
                <span
                  className="avatar-title rounded-circle bg-primary text-white">
                  <i className="bx bx-check-circle bx-sm"></i>
                </span>
              </div>
            </Col>
            <Col md={11}>
              <div className="mb-2">
                <h5 className="modal-title mt-0">Internal Check</h5>
                <p>Please choose from the following cases</p>
                <AvForm className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <AvRadioGroup
                    inline
                    name="status"
                    required
                    onChange={(e) => handleChange(e)}
                  >
                    <AvRadio customInput label="Warranty accepted" value="1"/>
                    <AvRadio customInput label="Internal accepted" value="2"/>
                    <AvRadio
                      customInput
                      label="Warranty not granted"
                      value="3"
                    />
                  </AvRadioGroup>
                  {
                      status === '1' ? <Row>
                        <Col md="6">
                          <AvField type="text"
                            name="customer"
                            label="Customer"
                            disabled
                            value={claim.customer && claim.customer.name}/>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="text"
                            name="dealer"
                            label="Dealer"
                            disabled
                            value={claim.dealer && claim.dealer.name}/>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="select"
                            name="problem_type"
                            label="Problem"
                            helpMessage="Please choose a valid option."
                            validate={{required: {value: true}}}
                          >
                            <option value=""></option>
                            {problemList.map((item, key) =>
                              <option key={key}
                                value={item.value}>{item.label}</option>,
                            )}
                          </AvField>
                        </Col>
                      </Row> : ''
                  }
                  {
                      status === '2' ? <Row>
                        <Col md="6">
                          <AvField
                            type="text"
                            name="customer"
                            label="Customer"
                            disabled
                            value={claim.customer && claim.customer.name}/>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="text"
                            name="dealer"
                            label="Dealer"
                            disabled
                            value={claim.dealer && claim.dealer.name}/>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="select"
                            name="reason_choice"
                            label="Reason"
                            helpMessage="Please choose a valid option."
                            onChange={(reasonChoice) => {
                              handleChangeReasonChoice(
                                  reasonChoice.target.value,
                              );
                            }}>
                            {reasonList.map(
                                /* eslint-disable camelcase */
                                (reason_item, key) =>
                                  <option key={key}
                                    value={reason_item.value}>
                                    {reason_item.label}
                                  </option>,
                                /* eslint-enable camelcase */
                            )}
                          </AvField>
                        </Col>
                        <Col md="6">
                          {reasonChoice === '3' ?
                            <AvField
                              type="text"
                              name="other_reason"
                              label="Other reason"
                            /> : ''
                          }
                        </Col>
                      </Row> : ''
                  }
                  {
                      status === '3' ? <Row>
                        <Col md="6">
                          <AvField
                            type="text"
                            name="customer"
                            label="Customer"
                            disabled
                            value={claim.customer && claim.customer.name}/>
                        </Col>
                        <Col md="6">
                          <AvField
                            type="text"
                            name="dealer"
                            label="Dealer"
                            disabled
                            value={claim.dealer && claim.dealer.name}
                          />
                        </Col>
                        <Col md="12">
                          <AvField
                            rows="5"
                            type="textarea"
                            name="individual_reply"
                            label="Message"
                            value=
                              {
                                'Dear ' + claim.engineer.firstname + ' ' +
                              claim.engineer.lastname +
                              ',\n\nWe checked the parts and we have ' +
                              'unfortunately inform you that we are unable ' +
                              'to confirm your claim. Should you require ' +
                              'further information please do not hesitate to ' +
                              'contact us via e-mail to ' +
                              'gtsupport@brother-ism.com. Please use the ' +
                              'Fault Report Number in the subject.'
                              }
                          />
                        </Col>
                      </Row> : ''
                  }
                  <Row className="mt-2">
                    <Col md="8">
                      <p>
                            After the submission, a technical manager will be
                            informed. He must finally confirm and close
                            the claim.
                      </p>
                    </Col>
                    <Col md="4">
                      <div className="text-sm-right">
                        {isLoading ?
                          <Button
                            className="btn btn-dark"
                            type="submit"
                            disabled={true}
                          >
                            <i
                              className="bx bx-loader bx-spin font-size-16
                              align-middle mr-2"/>
                              Submit for approval
                          </Button> :
                          <Button className="btn btn-dark" type="submit">
                              Submit for approval
                          </Button>
                        }
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          setStatus('');
          setReasonChoice('');
          history.push('/claims');
        }}
      >
          Internal check was submitted successfully and is now in review by our
          team.
      </SweetAlert> : null}
      {errorMsg ? <SweetAlert
        title="Request could not be submitted!"
        warning
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          setErrorMsg(false);
        }}
      >
          There was an error while submitting your request. Please try again or
          get in contact with our team.
      </SweetAlert> : null}
    </React.Fragment>
  );
};

export default ModalInternalCheck;
