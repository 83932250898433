import {
  GET_DEALERS_ERROR,
  GET_DEALERS_REQUEST,
  GET_DEALERS_SUCCESS,
} from './actionTypes';

export const getDealers = () => ({
  type: GET_DEALERS_REQUEST,
});

export const getDealersSuccess = (data) => {
  return {
    type: GET_DEALERS_SUCCESS,
    payload: data,
  };
};

export const getDealersError = (error) => {
  return {
    type: GET_DEALERS_ERROR,
    payload: error,
  };
};
