import {
  GET_MACHINES_ERROR,
  GET_MACHINES_REQUEST,
  GET_MACHINES_SUCCESS,
} from './actionTypes';

export const getMachines = () => ({
  type: GET_MACHINES_REQUEST,
});

export const getMachinesSuccess = (data) => {
  return {
    type: GET_MACHINES_SUCCESS,
    payload: data,
  };
};

export const getMachinesError = (error) => {
  return {
    type: GET_MACHINES_ERROR,
    payload: error,
  };
};


