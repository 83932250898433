/**
 * @description selector to get all dealers.
 * @param {*} state
 * @return {*}
 */
export const getDealersSelector: (state: any) => OverviewDealer[] =
    (state) => ((state.Dealers && state.Dealers.data) ?
      state.Dealers.data : []);

export const getDealerByIdSelector = (state: any, id: number) =>
  (state?.Dealer?.data?.id === id) ? state.Dealer.data : undefined;
