import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from 'reactstrap';
import {createLoadingSelector} from '../../../selectors/loading';
import {LoadingIndicator} from '../../LoadingIndicator';
import {EDIT_DEALER} from '../../../store/dealer/actionTypes';
import {DealerForm} from '../DealerForm';

interface DealerCardProps {
    dealer?: FullDealer,
    isLoading?: boolean
}

export const DealerCard: FC<DealerCardProps> = ({dealer, isLoading}) => {
  const [modal, setModal] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);

  const loadingSelector = useSelector(createLoadingSelector([EDIT_DEALER]));

  useEffect(() => {
    setModalIsLoading(loadingSelector);
  }, [loadingSelector]);

  return (
    <Card>
      <CardBody>
        <Media>
          <Media body>
            <h4 className="card-title">Dealer</h4>
            {!isLoading && dealer && <div>
              <h5 className="">{dealer.name}</h5>
              <p className="text-muted mb-1">
                {dealer.street} {dealer.street_no}
              </p>
              <p className="text-muted mb-1">{dealer.zip_code} {dealer.city}</p>
              <p className="text-muted mb-1">{dealer.country}</p>
            </div>}
            {isLoading && <LoadingIndicator />}
          </Media>
          <div className="avatar-sm ">
            <IconButton
              aria-label="edit"
              onClick={() => setModal(true)}
              disabled={isLoading}
            >
              <EditIcon/>
            </IconButton>
            <Modal
              size="lg"
              isOpen={modal}
              role="dialog"
              autoFocus={true}
              centered={true}
              className="dealerEditModal"
              toggle={() => {
                setModal(!modal);
              }}
            >
              <div className="modal-content">
                <ModalHeader toggle={() => {
                  setModal(!modal);
                }}>
                  {'Edit Dealer'} {modalIsLoading && <LoadingIndicator />}
                </ModalHeader>
                <ModalBody>
                  {dealer && <DealerForm dealer={dealer}/>}
                </ModalBody>

              </div>
            </Modal>
          </div>
        </Media>
      </CardBody>
    </Card>
  );
};
