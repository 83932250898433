import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from 'reactstrap';
import {createLoadingSelector} from '../../../selectors/loading';
import {LoadingIndicator} from '../../LoadingIndicator';
import {EDIT_MACHINE} from '../../../store/machine/actionTypes';
import Moment from 'react-moment';
import MachineStatus from '../../../pages/Machines/machine-status';
import {MachineForm} from '../MachineForm';
import {getLoggedInUser} from '../../../helpers/fakebackend_helper';
import {ROLE, RoleNameMap} from '../../Role/constants';

interface MachineCardProps {
    machine?: Machine,
    isLoading?: boolean
}

export const MachineCard: FC<MachineCardProps> = ({machine, isLoading}) => {
  const [modal, setModal] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);

  const authUser = getLoggedInUser(); // @TODO get the user from selector
  const userRole = RoleNameMap.get(authUser.user.role);

  const loadingSelector = useSelector(createLoadingSelector([EDIT_MACHINE]));

  useEffect(() => {
    setModalIsLoading(loadingSelector);
  }, [loadingSelector]);

  return (
    <Card>
      <CardBody>
        <Media>
          <Media body>
            <div className="d-flex mb-3">
              <div className="avatar-sm mr-3">
                <span
                  className="avatar-title rounded-circle
                            bg-primary text-white font-size-20">
                  <i className="bx bx-dumbbell"/>
                </span>
              </div>
              {!isLoading && machine &&
              <div className="mb-2">
                <div className="d-flex mb-2">
                  <MachineStatus machine={machine}/>
                </div>
                <div className="d-flex mb-2">
                  <i className='bx bx-power-off bx-xs mr-2'/>
                  <span className="text-truncate font-size-12">
                    {machine.installation_date ?
                       <Moment
                         format="DD-MM-YYYY"
                       >
                         {machine.installation_date}
                       </Moment> : 'Not installed'
                    }
                  </span>
                </div>
              </div>
              }
              {isLoading && <div className="mb-2"><LoadingIndicator /></div>}
            </div>
          </Media>
          { (userRole === ROLE.BROTHER_MANAGER ||
              userRole === ROLE.BROTHER_STAFF ||
              userRole === ROLE.DEALER_ENGINEER) &&
          <div className="avatar-sm">
            <IconButton
              aria-label="edit"
              onClick={() => setModal(true)}
              disabled={isLoading}
            >
              <EditIcon/>
            </IconButton>
            <Modal
              size="lg"
              isOpen={modal}
              role="dialog"
              autoFocus={true}
              centered={true}
              className="machineEditModal"
              toggle={() => {
                setModal(!modal);
              }}
            >
              <div className="modal-content">
                <ModalHeader toggle={() => {
                  setModal(!modal);
                }}>
                  {'Edit Machine'} {modalIsLoading && <LoadingIndicator />}
                </ModalHeader>
                <ModalBody>
                  {machine && <MachineForm machine={machine}/>}
                </ModalBody>
              </div>
            </Modal>
          </div>}
        </Media>
      </CardBody>
    </Card>
  );
};
