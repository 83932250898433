import React, {useState} from 'react';
import {Button, Row, Col, Modal} from 'reactstrap';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';

const ModalAddComment = (props) => {
  const claim = props.claim;

  const [modal, setModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const model = {};

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function toggleModal() {
    setModal(!modal);
    removeBodyCss();
  }

  function handleValidSubmit(event, values) {
    const comment = {
      comment: values.comment,
    };
    setIsLoading(true);
    sendComment(comment);
  }

  function sendComment(comment) {
    const url = process.env.REACT_APP_APIURL + 'claim/' + claim.id + '/comment';
    const authToken = authHeader();

    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    return axios.post(url, formData, {headers: authToken}).then((response) => {
      setIsLoading(false);
      setSuccessMsg(true);
    }).catch((error) => {
      setIsLoading(false);
      setErrorMsg(true);
    });
  }

  return (
    <React.Fragment>
      <button
        className="mr-1 waves-effect waves-light btn btn-yellow text-white"
        type="button"
        onClick={() => {
          toggleModal();
        }}
        data-toggle="modal"
        data-target=".bs-example-modal-center"
      >
        <i className="bx bx-plus pt-1"></i> Add Comment
      </button>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <button
            type="button"
            onClick={() => {
              setModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Row>
            <Col md={1}>
              <div className="avatar-sm">
                <span
                  className="avatar-title rounded-circle bg-primary text-white">
                  <i className="bx bx-refresh bx-sm rotateInUpLeft"></i>
                </span>
              </div>
            </Col>
            <Col md={11}>
              <div className="mb-2">
                <h5 className="modal-title mt-0">Add Internal Comment</h5>
              </div>
              <div className="mb-3">
                <AvForm
                  className="form-horizontal mt-4"
                  model={model}
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <Row>
                    <Col md="12">
                      <AvField
                        rows="5"
                        type="textarea"
                        name="comment"
                        label="Comment"
                        required
                        errorMessage="Please provide a valid request."/>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className="text-sm-right">
                        {isLoading ?
                          <Button
                            className="btn btn-yellow"
                            type="submit"
                            disabled={true}>
                            <i
                              className="bx bx-loader bx-spin font-size-16
                              align-middle mr-2"
                            /> Add Internal Comment
                          </Button> :
                          <Button className="btn btn-yellow" type="submit">
                            <i className="bx bx-plus pt-1"/>
                              Add Internal Comment
                          </Button>
                        }
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          window.location.reload();
        }}
      >
          Comment was submitted successfully.
      </SweetAlert> : null}
      {errorMsg ? <SweetAlert
        title="Request could not be submitted!"
        warning
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          setErrorMsg(false);
        }}
      >
          There was an error while submitting your request. Please try again or
          get in contact with our team.
      </SweetAlert> : null}
    </React.Fragment>
  );
};

export default ModalAddComment;
