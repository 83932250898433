import authHeader from '../helpers/jwt-token-access/auth-token-header';
import axios from 'axios';

const getDealerFromBackend = (dealerId: number) => {
  const url = process.env.REACT_APP_APIURL + 'dealer/'+ dealerId;
  const authToken = authHeader();

  return axios.get(url, {headers: authToken}).then((response) => {
    if (response.status === 400 || response.status === 500) {
      throw response.data;
    }
    return response.data;
  }).catch((err) => {
    throw err[1];
  });
};

const createDealerInBackend = (dealer: CreateAPIDealer) => {
  const url = process.env.REACT_APP_APIURL + 'dealer';
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(dealer));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    // if (response.status === 400 || response.status === 500)
    //     throw response.data;
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
};

const editDealerInBackend = (dealer: EditAPIDealer) => {
  const url = process.env.REACT_APP_APIURL + 'dealer/edit/' + dealer.id;
  const authToken = authHeader();

  const formData = new FormData();
  formData.append('data', JSON.stringify(dealer));

  return axios.post(url, formData, {headers: authToken}).then((response) => {
    return response.data;
  }).catch((error) => {
    return error.response.data;
  });
};

export {getDealerFromBackend, createDealerInBackend, editDealerInBackend};
