import React, {useState} from 'react';
import {Button, Row, Col, Modal, Card, Label} from 'reactstrap';
import {AvGroup, AvInput, AvForm} from 'availity-reactstrap-validation';
import authHeader from '../../helpers/jwt-token-access/auth-token-header';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import {getLoggedInUser} from '../../helpers/fakebackend_helper';
import {ROLE, RoleNameMap} from '../Role/constants';

const ModalMachineAddFile = (props) => {
  const machine = props.machine;

  const [modal, setModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const model = {};

  const authUser = getLoggedInUser();
  const userRole = RoleNameMap.get(authUser.user.role) || ROLE.NONE;

  function removeBodyCss() {
    document.body.classList.add('no_padding');
  }

  function toggleModal() {
    setModal(!modal);
    removeBodyCss();
  }

  function handleValidSubmit(event, values) {
    setIsLoading(true);
    uploadFile(values);
  }

  function uploadFile(data) {
    const url = process.env.REACT_APP_APIURL + 'machine/' + machine.id +
      '/upload';
    const authToken = authHeader();

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files[]', file);
    });
    formData.append('data', JSON.stringify(data));

    return axios.post(url, formData, {headers: authToken}).then((response) => {
      setSuccessMsg(true);
    }).catch((error) => {
      setIsLoading(false);
      setErrorMsg(true);
    });
  }

  function handleAcceptedFiles(files) {
    files.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }));

    setSelectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <React.Fragment>
      <button
        className="position-absolute top-15 right-15 p-0 waves-effect
        waves-light btn btn-transparent text-yellow"
        type="button"
        onClick={() => {
          toggleModal();
        }}
        data-toggle="modal"
        data-target=".bs-example-modal-center"
      >
        <i className="font-size-24 bx bxs-cloud-upload"></i>
      </button>
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          toggleModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <button
            type="button"
            onClick={() => {
              setModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Row>
            <Col md={1}>
              <div className="avatar-sm">
                <span
                  className="avatar-title rounded-circle bg-primary text-white">
                  <i className="bx bxs-cloud-upload bx-sm"></i>
                </span>
              </div>
            </Col>
            <Col md={11}>
              <div className="mb-2">
                <h5 className="modal-title mt-0">Upload files for machine {machine && machine.machine_serial_no}</h5>
              </div>
              <div className="mb-3">
                <AvForm
                  className="form-horizontal mt-4"
                  model={model}
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}>
                  <Row>
                    <Col md="12">
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                        accept=".pdf,.log"
                        multiple={true}
                      >
                        {({getRootProps, getInputProps}) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx
                                  bxs-cloud-upload"/>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border
                              dz-processing dz-image-preview dz-success
                              dz-complete"
                              key={i + '-file'}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="12">
                      { (userRole === ROLE.BROTHER_STAFF ||
                          userRole === ROLE.BROTHER_MANAGER) && <AvGroup check>
                        <Label check>
                          <AvInput type="checkbox" name="is_restricted" trueValue="1" falseValue="0" /> Only visible to Brother members
                        </Label>
                      </AvGroup>
                      }
                      {isLoading ?
                        <Button
                          className="btn btn-yellow d-flex align-items-center
                          ml-auto"
                          type="submit"
                          disabled={true}
                        >
                          <i className="bx bx-loader bx-spin font-size-16
                          align-middle mr-2"/>
                            Upload files
                        </Button> :
                        <Button
                          className="btn btn-yellow d-flex align-items-center
                          ml-auto"
                          type="submit"
                        >
                          <i className="bx bxs-cloud-upload font-size-24 mr-2"/>
                            Upload files
                        </Button>
                      }
                    </Col>
                  </Row>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      {successMsg ? <SweetAlert
        title="Good job!"
        success
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          window.location.reload();
        }}
      >
                File was uploaded successfully.
      </SweetAlert> : null}
      {errorMsg ? <SweetAlert
        title="Request could not be submitted!"
        warning
        showConfirm={true}
        showCancel={false}
        confirmBtnBsStyle="yellow"
        onConfirm={() => {
          setErrorMsg(false);
        }}
      >
          There was an error while submitting your request. Please try again or
          get in contact with our team.
      </SweetAlert> : null}
    </React.Fragment>
  );
};

export default ModalMachineAddFile;
